<template>
    <ClassSelection />
</template>

<script>
import ClassSelection from "@/pages/ClassSelection/ClassSelection"


export default {
    data: () => {
        return {
        }
    },
    components: {
        ClassSelection
    }
}
</script>

<style>

</style>