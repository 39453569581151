<template>
  <v-card
    class="mx-auto"
    max-width="1024"
  >
    <v-img
      class="white--text align-end bann"
      height="300px"
      v-bind:style="{ backgroundImage: 'url(../about.png)' }"
    >
      <v-card-title>Hello everyone!</v-card-title>
    </v-img>

    <v-card-subtitle class="pb-0">
      Vinter here!
    </v-card-subtitle>

    <v-card-text class="text--primary">
      <div>
        First I'd like to thank you for using this site. I made this site for you and for all the MU Online community so that we may finally stop wasting WC resetting Trees and Stats over and over again or use outdated, clunky excel sheets to plan out our characters.<br>
        This site started as a personal project to plan out my Summoner build but due to popular demand I turned it into a proper website for public use.<br><br>

        If you have any suggestions, or just want to chat, join our discord server! It's small, but everyone's welcome!
      </div>
    </v-card-text>

    <!-- <v-card-actions>
      <v-btn
        color="orange"
        text
      >
        Share
      </v-btn>

      <v-btn
        color="orange"
        text
      >
        Explore
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
export default {

}
</script>

<style>
.bann {
    background-size: 100%;
    background-position: center;
}
</style>