<template>
  <div class="notification">
    <v-alert 
        dense
        type="error"
        color="#D54420"
    >
      {{notification.text}}
    </v-alert>
    <!-- <div class="notification__text">
      <span v-html="notification.text"></span>
    </div> -->
  </div>
</template>

<script>

export default {
  props: {
    notification: Object
  },
  methods: {
    removeNotification() {
      this.$store.dispatch('removeNotification', this.notification.guid);
    }
  },
  created() {
    if (!this.notification.withAction) {
      setTimeout(() => {
        this.removeNotification();
      }, this.notification.timeout || 3000);
    }
  }
};
</script>

<style scoped>

.notification {
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 400px;
}
</style>
