<template>
  <div class="notifications">
    <template v-for="(notification, indexNotification) in notifications">
      <Notification :notification="notification" :key="indexNotification" />
    </template>
  </div>
</template>

<script>
import Notification from './Notification.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Notification
  },
  computed: {
    ...mapState({
      notifications: (state) => state.notifications.list
    })
  }
};
</script>

<style scoped>

.notifications {
  position: fixed;
  bottom: 40px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
