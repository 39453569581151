import Season15Part2 from './Seasons/Season15Part2/Season15Part2';
import Season16Part1 from './Seasons/Season16Part1/Season16Part1';
import Season16Part2 from './Seasons/Season16Part2/Season16Part2';
import Season17Part1 from './Seasons/Season17Part1/Season17Part1';
import Season17Part2 from './Seasons/Season17Part2/Season17Part2';
import Season18Part1 from './Seasons/Season18Part1/Season18Part1';

var Formulas = {
    Seasons: [],
    calculate: function(statId) {
        //TODO: pasar a que use la seleccionada como base y que busque para atras para no tener que repetir todas las formulas de todas las clases en todas las season
        return this.Seasons[window.store.state.Planner.SelectedSeason.id].calculate(statId);
    }
}

Formulas.Seasons[1] = Season15Part2;
Formulas.Seasons[2] = Season16Part1;
Formulas.Seasons[3] = Season16Part2;
Formulas.Seasons[4] = Season17Part1;
Formulas.Seasons[5] = Season17Part2;
Formulas.Seasons[6] = Season18Part1;

export default Formulas;