<template>
    <div 
      v-on:click="selectClass(id)"
      class="columns"
      v-bind:style="{ background: 'url(../../../classes/' + this.code + '.png) center top / cover no-repeat' }" 
    >
      <div class="content-title">{{name}}</div>
    </div>
</template>

<script>
export default {
    props: { 
        id: {
            type: Number,
            default: 0,
        },
        name: {
            type: String,
            default: ""
        },
        code: {
            type: String,
            default: ""
        },
        seasonData: {
            type: Array,
        },
    },
    methods: {
      //todo: move this to ClassSelection.vue
      selectClass(classId) {
        this.$store.commit('SetSelectedClassId', classId);
        this.$router.push({ name: 'Planner' });
      }
    }, 
}
</script>

<style>

.content-title {
  width: 100%;
  text-align: center;
  opacity: 0.0;
  transition: all 0.5s ease-in-out;
  position: relative;
  top: 3%;
  font-size: 20px;
  font-family: 'Marcellus',sans-serif;
  background: linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              );
}

.columns {
  width: 9.09%;
  transition: all 0.2s ease-in-out;
  height: 100%;
  flex: 1 1 auto;
}

.columns:hover > .content-title {
  opacity: 1.0;
}

.columns:hover {
  width:30%; 
}

</style>