// import Interceptor from '@/plugins/interceptor';

// const URL = `${process.env.VUE_APP_PLANNER_API_URL}`;
const Inventory = {
  state: {
    slots: [],
  },
  actions: {
    // async Classes_getAllClasses({ commit }, seasonId) {
    //   const response = await Interceptor.get2(`${URL}/getAllClasses?seasonId=${seasonId}`);
    //   commit('SetClasses', response.data);
    // },
  },
  getters: {
    // selectedClass: state => {
    //   return state.Classes.find(x => x.id === state.SelectedClassId);
    // }
  },
  mutations: {
    InitializeInventorySlots(state) {
        state.slots[0] = {image: "/items/13-1.gif"};  // Pet
        state.slots[1] = {image: "/items/13-12.gif"}; // Pend
        state.slots[2] = {image: "/items/7-3.gif"}; // Helm
        state.slots[3] = {image: "/items/12-414.gif"}; // Wings
        state.slots[4] = {image: "/items/5-49.gif"}; // Mainhand
        state.slots[5] = {image: "/items/12-457.gif"}; // Earring R
        state.slots[6] = {image: "/items/8-3.gif"}; // Armor
        state.slots[7] = {image: "/items/12-449.gif"}; // Earring L
        state.slots[8] = {image: "/items/6-27.gif"}; // Offhand
        state.slots[9] = {image: "/items/10-3.gif"}; // Gloves
        state.slots[10] = {image: "/items/13-169.gif"}; // Ring R
        state.slots[11] = {image: "/items/9-3.gif"}; // Pants
        state.slots[12] = {image: "/items/13-170.gif"}; // Ring L
        state.slots[13] = {image: "/items/11-3.gif"}; // Boots
        state.slots[14] = {image: "/items/13-3.gif"}; // Guardian
        state.slots[15] = {image: "/items/12-308-2.gif"}; // Penta
        // for(let i = 1; i < 16; i++) { //16 slots
        //     state.slots[i] = {};
        // } 
    },
  },
}

export default Inventory;