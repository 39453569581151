import StatConsts from '@/Formulas/StatConsts.js'

// const level = 400;

// function getMultiplier(stat) {
//     const section1 = 1500;
//     const section1Percentage = 10;
//     const section2 = 2000;
//     const section2Percentage = 30;
//     const section3 = 3000;
//     const section3Percentage = 45;

//     var percentage = 1;
//     if (stat <= section1) {
//         percentage += (section1Percentage / section1) * stat / 100;
//     }
//     if (stat > section1 && stat <= section2) {
//         percentage += section1Percentage / 100 + ((section2Percentage - section1Percentage) / (section2 - section1)) * (stat - section1) / 100;
//     }
//     if (stat > section2 && stat <= section3) {
//         percentage += section2Percentage / 100 + ((section3Percentage - section2Percentage) / (section3 - section2)) * (stat - section2) / 100;
//     }
//     if (stat > section3) {
//         percentage += section3Percentage / 100;
//     }
//     return percentage;
// }

// function calcMinAttackPower() {
//     return parseInt((this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) / 8 + this.CurrentStats[StatConsts.MinAttackPower]);
// }

// function calcMaxAttackPower() {
//     return parseInt((this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) / 4 + this.CurrentStats[StatConsts.MaxAttackPower]);
// }

// function calcAttackSuccessRate() {
//     return parseInt(level * 5 + (this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) / 4 + (this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) * 1.5 * getMultiplier(this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) + this.CurrentStats[StatConsts.AtkSuccRate])
// }

// function calcPvPAttackSuccessRate() {
//     return parseInt(level * 3 + (this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) * 4 * getMultiplier(this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) + this.CurrentStats[StatConsts.PvPAtkRate])
// }

// function calcDefense() {
//     return parseInt((this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 4 + this.CurrentStats[StatConsts.Defense])
// }

// function calcAttackSpeed() {
//     return parseInt((this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 15 + this.CurrentStats[StatConsts.AttackSpeed]) + "/ 280"
// }

// function calcDefenseRate() {
//     return parseInt((this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 3 * (this.CurrentStats[StatConsts.DefenseRate] / 100 + 1))
// }

// function calcPvPDefenseRate() {
//     return parseInt((level * 2) + (this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 4 + this.CurrentStats[StatConsts.PvPDefRate])
// }

// function calcMinWizardryDmg() {
//     //energy / 9 * bonus + skill's power
//     const energyBallSkillDamage = 0;//3 + parseInt((this.currentEnergy + this.CurrentStats[StatConsts.Energy] + this.CurrentStats[StatConsts.AdditionalEnergy]) / 30);
//     return parseInt(energyBallSkillDamage + (this.currentEnergy + this.CurrentStats[StatConsts.Energy] + this.CurrentStats[StatConsts.AdditionalEnergy]) / 9 * getMultiplier(this.currentEnergy + this.CurrentStats[StatConsts.Energy] + this.CurrentStats[StatConsts.AdditionalEnergy]) + this.CurrentStats[StatConsts.MinWizardryDmg])
// }

// function calcMaxWizardryDmg() {
//     //energy / 9 * bonus + skill's power
//     const energyBallSkillDamage = 0;//3 + parseInt((this.currentEnergy + this.CurrentStats[StatConsts.Energy] + this.CurrentStats[StatConsts.AdditionalEnergy]) / 30 * 1.5);
//     return parseInt(energyBallSkillDamage + (this.currentEnergy + this.CurrentStats[StatConsts.Energy] + this.CurrentStats[StatConsts.AdditionalEnergy]) / 4 * getMultiplier(this.currentEnergy + this.CurrentStats[StatConsts.Energy] + this.CurrentStats[StatConsts.AdditionalEnergy]) + this.CurrentStats[StatConsts.MaxWizardryDmg])
// }

function calcAdditionalWizardryDmg() {
    //TODO: this when gear is on
    return "-"
}

var WW = {
    Stats: [],
    calculate: function(statId) {
        if(!this.Stats[statId]) {
            return "?";
        }
        return this.Stats[statId].call(window.store.state.Planner);
    }
}

//WW.Stats[StatConsts.MinAttackPower] = calcMinAttackPower;
//WW.Stats[StatConsts.MaxAttackPower] = calcMaxAttackPower;
//WW.Stats[StatConsts.AtkSuccRate] = calcAttackSuccessRate;
//WW.Stats[StatConsts.PvPAtkRate] = calcPvPAttackSuccessRate;
//WW.Stats[StatConsts.Defense] = calcDefense;
// WW.Stats[StatConsts.AttackSpeed] = calcAttackSpeed;
//WW.Stats[StatConsts.DefenseRate] = calcDefenseRate;
//WW.Stats[StatConsts.PvPDefRate] = calcPvPDefenseRate;
//WW.Stats[StatConsts.MinWizardryDmg] = calcMinWizardryDmg;
//WW.Stats[StatConsts.MaxWizardryDmg] = calcMaxWizardryDmg;
WW.Stats[StatConsts.AdditionalWizardryDmg] = calcAdditionalWizardryDmg;

export default WW;