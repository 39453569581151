<template>
<v-dialog
    v-model="dialog"
    width="700"
    @click:outside="closeDialog"
>
    <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        Master Tree
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Stats
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">
        Hover
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card
          class="mx-auto card"
          height="300px"
        >
            <v-img
                class="white--text align-end bann"
                height="100%"
                v-bind:style="{ backgroundImage: 'url(../stepper1.png)' }"
            >
                <v-card-title>
                    Left Click: increase by 1 point.<br>
                    Right Click: decrease by 1 point.<br>
                    Shift + Click: increase / decrease by 10 points.
                </v-card-title>
            </v-img>
        </v-card>

        <v-btn
          color="primary"
          @click="e1 = 2"
        >
          Continue
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card
          class="mx-auto card"
          height="300px"
        >
            <v-img
                class="white--text align-end bann"
                height="100%"
                v-bind:style="{ backgroundImage: 'url(../stepper2.png)' }"
            >
                <v-card-title>
                    Left Click: increase by 1 point.<br>
                    Right Click: decrease by 1 point.<br>
                    Shift + Click: increase / decrease by 100 points.<br>
                    Ctrl + Click: increase / decrease by 10 points.
                </v-card-title>
            </v-img>
        </v-card>

        <v-btn
          color="primary"
          @click="e1 = 3"
        >
          Continue
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card
          class="mx-auto card"
          height="300px"
        >
            <v-img
                class="white--text align-end bann"
                height="100%"
                v-bind:style="{ backgroundImage: 'url(../stepper3.png)' }"
            >
                <v-card-title>
                    Hover over stats to know their origin!<br>
                </v-card-title>
            </v-img>
        </v-card>

        <v-btn
          color="primary"
          @click="closeDialog()"
        >
          Close
        </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</v-dialog>
</template>

<script>
  export default {
    data () {
      return {
        e1: 1,
      }
    },
    props: {
        dialog: {
            Type: Boolean,
            Default: false,
        },
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
  }
</script>

<style>
    .card {
        margin-bottom: 30px;
    }
</style>