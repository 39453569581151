import BM from './BM'
import SM from './SM'
import ELF from './ELF'
import MG from './MG'
import DL from './DL'
import SUM from './SUM'
import RF from './RF'
import GL from './GL'
import RW from './RW'
import SL from './SL'

var Season15Part2 = {
    Classes: [],
    calculate: function(statId) {
        return this.Classes[window.store.state.Planner.SelectedClassId].calculate(statId);
    }
}

Season15Part2.Classes[1] = BM;
Season15Part2.Classes[2] = SM;
Season15Part2.Classes[3] = ELF;
Season15Part2.Classes[4] = MG;
Season15Part2.Classes[5] = DL;
Season15Part2.Classes[6] = SUM;
Season15Part2.Classes[7] = RF;
Season15Part2.Classes[8] = GL;
Season15Part2.Classes[9] = RW;
Season15Part2.Classes[10] = SL;

export default Season15Part2;