const MasterTree = {
  state: {
    MasterTree: {},
    MasterTreePoints: 0,
    MasterTreeSkills: [],
    ctrlPressed: false,

    SkillEnhancementTree: {}, 
    SkillEnhancementTreePoints: 0,
    SkillEnhancementSkills: [],
    SkillEnhancementTreeSkillLinePoints: 0,
    SkillEnhancementTreeDebuffPoints: 0,
    SkillEnhancementPassives: [],
    PlannerData: {},
  },
  actions: {
    async MasterTree_getMasterTree({ commit }, value) {
      var path = "";
      var found = false;
      for (let i = value.seasonId; i >= 1; i--) {
        path = `/PlannerData/Seasons/${i}/${value.classId}.json`;
        await fetch(path).then((res) => res.json()).then((json) => 
        {
          commit('SetMasterTree', json.masterTree);
          commit('SetSkillEnhancementTree', json.skillEnhancementTree);
          commit('SetStats', json.stats);
          found = true;
        })
        .catch((err) => 
        {
          console.log(err)
        });
        if(found)
          break;
      }

    },
  },
  getters: {
    getCtrlPressed: state => state.ctrlPressed,
  },
  mutations: {
    SetPlannerData(state, value) {
      state.PlannerData = value;
    },
    //Master Tree
    SetMasterTree(state, value) {
      state.MasterTree = value;
    },
    SetMasterTreeSkills(state, value) {
      state.MasterTreeSkills = value;
    },
    SetMasterTreePoints(state, value) {
      state.MasterTreePoints = value;
    },
    AddMasterTreeSkill(state, value) {
      state.MasterTreeSkills.push(value)
    },
    SetMasterTreeSkillLevel(state, value) {
      state.MasterTreeSkills.find(x => value.id === x.id).currentLevel = value.currentLevel;
    },
    SetCtrlPressed(state) {
      state.ctrlPressed = true;
    },
    SetCtrlUnpressed(state) {
      state.ctrlPressed = false;
    },

    //Skill Enhancement 
    SetSkillEnhancementTree(state, value) {
      state.SkillEnhancementTree = value;
      state.SkillEnhancementSkills = [];
      for (let i = 0; i < value.pages.length + 1; i++) {
        state.SkillEnhancementSkills.push([]);  
      }
    },
    AddSkillEnhancementSkill(state, value) {
      state.SkillEnhancementSkills[value.pageId].push(value)
    },
    SetSkillEnhancementPoints(state, value) {
      state.SkillEnhancementTreePoints = value;
    },
    IncreaseSkillEnhancementPoints(state, value) {
      state.SkillEnhancementTreePoints += value;
    },
    IncreaseSkillEnhancementSkillLinePoints(state, value) {
      state.SkillEnhancementTreeSkillLinePoints += value;
    },
    IncreaseSkillEnhancementDebuffLinePoints(state, value) {
      state.SkillEnhancementTreeSkillLinePoints += value;
    },
    SetSkillEnhancementSkillLinePoints(state, value) {
      state.SkillEnhancementTreeSkillLinePoints = value;
    },
    SetSkillEnhancementDebuffLinePoints(state, value) {
      state.SkillEnhancementTreeDebuffPoints = value;
    },
    SetSkillEnhancementSkillLevel(state, value) {
      state.SkillEnhancementSkills[value.pageId].find(x => value.id === x.id).currentLevel = value.currentLevel;
    },
    SetSkillEnhancementSkills(state, value) {
      state.SkillEnhancementSkills = value;
    },

    //Passives
    AddSkillEnhancementPassive(state, value) {
      state.SkillEnhancementPassives.push(value)
      // state.SkillEnhancementSkills.push(value)
    },
    SetSkillEnhancementPassives(state, value) {
      state.SkillEnhancementPassives = value;
    },

    SetkillEnhancementPassiveLevel(state, value) {
      state.SkillEnhancementPassives.find(x => value.id === x.id).currentLevel = value.currentLevel;
      // state.SkillEnhancementSkills.find(x => value.id === x.id).currentLevel = value.currentLevel;
    },
  },
}

export default MasterTree;