import Vue from 'vue';
import Vuex from 'vuex';

import MasterTree from '@/store/MasterTree';
import Planner from '@/store/Planner';
import notifications from '@/store/Notifications';
import Inventory from '@/store/Inventory';
Vue.use(Vuex);

window.store = new Vuex.Store({
  modules: {
    MasterTree,
    Planner,
    notifications,
    Inventory
  }
});

export default window.store;
