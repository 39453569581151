import BM from './BM'
import SM from './SM'
import ELF from './ELF'
import MG from './MG'
import DL from './DL'
import SUM from './SUM'
import RF from './RF'
import GL from './GL'
import RW from './RW'
import SL from './SL'
import GC from './GC'

var Season16Part1 = {
    Classes: [],
    calculate: function(statId) {
        return this.Classes[window.store.state.Planner.SelectedClassId].calculate(statId);
    }
}

Season16Part1.Classes[1] = BM;
Season16Part1.Classes[2] = SM;
Season16Part1.Classes[3] = ELF;
Season16Part1.Classes[4] = MG;
Season16Part1.Classes[5] = DL;
Season16Part1.Classes[6] = SUM;
Season16Part1.Classes[7] = RF;
Season16Part1.Classes[8] = GL;
Season16Part1.Classes[9] = RW;
Season16Part1.Classes[10] = SL;
Season16Part1.Classes[11] = GC;

export default Season16Part1;