<template>
  <div class="rightSide">
    <v-dialog
      v-model="dialog"
      width="700"
    @click:outside="closeDialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="HelpButton" 
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-card>
        <v-card-title>
          Useful shortcuts
        </v-card-title>
        <v-card-text>

          <div class="flex">
            <div class="text">
              Add 100 stat points at a time
            </div>
            <div class="keybindWrapper">
              <div class="keybind">SHIFT</div><div class="keybind">CLICK</div>
            </div>
          </div>
          <br>

          <div class="flex">
            <div class="text">
              Add 10 stat points at a time
            </div>
            <div class="keybindWrapper">
              <div class="keybind">CTRL</div><div class="keybind">CLICK</div>
            </div>
          </div>
          <br>

          <div class="flex">
            <div class="text">
              Add 10 Tree Skill's points at a time
            </div>
            <div class="keybindWrapper">
              <div class="keybind">SHIFT</div><div class="keybind">CLICK</div>
            </div>
          </div>
          <br>

          <div class="flex">
            <div class="text">
              Show Tree Skill's values table
            </div>
            <div class="keybindWrapper">
              <div class="keybind">CTRL</div>
            </div>
          </div>
          <br>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
      this.dialog = false;
    },
  },
}
</script>

<style scoped>
.text {
  align-self: center;
}

.keybindWrapper {
  display: flex;
  align-self: flex-end;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.keybind {
  background-color: #36393f;
  border: none;
  padding: 5px 10px;
  text-align: center;
  margin: 4px 2px;
  border-radius: 6px;
  font-weight: 500;
  font-size: .75rem;
  font-family: Roboto,sans-serif;
  border-bottom: 5px solid black;
}

.rightSide {
  align-self: right;
}

.HelpButton {
  background-image: url("../assets/HelpButtonIdle.png");
  margin-right: 1%;
  background-size: 100% 100%;
  height: 64px;
  width: 64px;
  display: flex;
}

.HelpButton:hover {
  background-image: url("../assets/HelpButtonHover.png");
}

</style>