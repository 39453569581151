<template> 
    <div class="MasterTreeSkillWrapper">
        <div class="MasterTreeSkillBackground"
            v-bind:style="[this.hidden ? {'visibility': 'hidden'} : {'visbility': 'visible'}]"
        >
                <div 
                    class="MasterTreeSkillIcon skill-tooltip" 
                    v-bind:class="[!CanAddPoints() ? 'MasterTreeSkillIconDisabled' : '']"
                    @contextmenu="decreaseCurrentLevel($event)" 
                    v-on:click="increaseCurrentLevel($event)"
                    v-bind:style="{ backgroundImage: 'url(../../../imgs/new_Master_Icon_' + this.iconId.toString().padStart(2, '0') + '.png)' }"
                    @mouseenter="focus = true"
                    @mouseleave="focus = false"
                />
                <span class="skill-tooltip-text"
                    v-if="focus"
                    v-bind:class="[ this.tooltipUp ? 'tooltipUp' : '', this.tooltipLeft ? 'tooltipLeft' : '' ]"
                >
                    <span class="MasterTreeSkillTitle">{{this.name}}</span><br>
                    {{$t("MasterTree.MasterTreeSkillRank", { rank: this.rank, currentLevel: this.currentLevel, maxLevel: this.maxLevel })}}<br>
                    {{GetDescription(this.getCurrentValue())}} 

                    <div v-if="focus && this.$store.getters.getCtrlPressed" style="display: flex;">
                        <div class="skillValueTable">
                            <div v-for="(level, index) in values" :key="index">
                                <div v-if="index < maxLevel / 2">
                                    <span v-bind:class="[level.level === currentLevel ? 'Gold' : '']">Level {{level.level}}: {{level.skillValue}}<br></span>
                                </div>
                            </div> 
                        </div>
                        <div class="skillValueTable">
                            <div v-for="(level, index) in values" :key="index">
                                <div v-if="index >= maxLevel / 2">
                                    <span v-bind:class="[level.level === currentLevel ? 'Gold' : '']">Level {{level.level}}: {{level.skillValue}}<br></span>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div v-else>
                        <span 
                            class="NextLevelTitle"                 
                            v-if="ShouldShowNextDescription()"
                        >
                            <br>
                            {{$t(`MasterTree.MasterTreeSkillNextLevel`)}}<br>
                        </span>                        
                        <span             
                            v-if="ShouldShowNextDescription()"
                        >
                        {{GetDescription(this.getNextValue())}}
                        </span>        
                        <div v-if="ShouldShowRequirements()"> 
                            <br>
                            <span class="RequirementsTitle">{{$t("MasterTree.MasterTreeSkillRequirements")}}</span><br>
                            <span
                                v-bind:class="[!IsPointRequirementMet() ? 'RequirementNotMet' : '']"
                            >
                                {{$t("MasterTree.PointRequirement", { points: this.requirements ? this.requirements.points : 0 })}}
                            </span>
                            <br>
                            <span 
                                v-if="this.requirements.rank"
                                v-bind:class="[!IsRankRequirementMet() ? 'RequirementNotMet' : '']"
                            >
                                {{$t("MasterTree.RankRequirement", { rank: this.requirements.rank, level: this.requirements.level })}}
                            </span><br>
                            <span 
                                v-if="this.requirements.requiredSkillId"
                                v-bind:class="[!IsRequiredSkillRequirementMet() ? 'RequirementNotMet' : '']"
                            >
                                {{$t("MasterTree.SkillRequirement", { rank: getSkillRank(this.requirements.requiredSkillId), level: this.requirements.level, skillName: getSkillName(this.requirements.requiredSkillId) })}}
                            </span>
                            <span 
                                v-if="this.requirements.requiredSkillId_2"
                                v-bind:class="[!IsRequiredSkillRequirementMet() ? 'RequirementNotMet' : '']"
                            >
                                <br>{{$t("MasterTree.SkillRequirement", { rank: getSkillRank(this.requirements.requiredSkillId_2), level: this.requirements.level, skillName: getSkillName(this.requirements.requiredSkillId_2) })}}
                            </span>
                        </div>
                    </div>                
                </span>
            <div id="MasterTreeSkillCounter">
                {{this.currentLevel}}
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            treePoints: (state) => state.MasterTree.MasterTreePoints,
            treeSkills: (state) => state.MasterTree.MasterTreeSkills,
        })
    },
    created() {
        if(this.id != 0) {
            if(this.currentLevelFromBuild != 0) {
                this.currentLevel = this.currentLevelFromBuild;
                this.increaseColumnLevel(this.currentLevelFromBuild);
            }
            this.$store.commit('AddMasterTreeSkill', { id: this.id, rank: this.rank, currentLevel: this.currentLevel, columnId: this.columnId, requiredSkillId: this.requirements.requiredSkillId, requiredRank: this.requirements?.rank, requiredLevel: this.requirements.level, skillName: this.name });
        }
    },
    data: function() {
        return {
            currentLevel: 0,
            focus: false
        }
    },
    props: {
        increaseColumnLevel: {
            type: Function
        },
        decreaseColumnLevel: {
            type: Function
        },
        id: {
            type: Number,
            Default: 0
        },
        name: {
            type: String,
            Default: ""
        },
        description: {
            type: String,
            Default: ""
        },
        maxLevel: {
            type: Number,
            Default: 20
        },
        rank: {
            type: Number,
            Default: 0
        },
        hidden: {
            type: Boolean,
            Default: false
        },
        requirements: {
            tpye: Object,
            Default: {}
        },
        columnId: {
            type: Number,
            default: 0
        },
        tooltipUp: {
            type: Boolean,
            default: false
        },
        tooltipLeft: {
            type: Boolean,
            default: false
        },
        values: {
            tpye: Object,
            Default: {}
        },
        currentLevelFromBuild: {
            type: Number,
            Default: 0
        },
        iconId: {
            type: Number,
            Default: 0
        },
        descriptionId: {
            type: Number,
            Default: 0
        },
        affectedStats: {
            type: Array,
            Default: []
        }
    },
    methods: { 
        
        getValueTableDiff(index) {
            if (index == 0){
                return `(+${this.values[0].skillValue})`;
            }
            var retValue = Number.parseFloat(this.values[index - 1].skillValue);
            if(Number.isInteger(retValue)) {
                return `(+${this.values[index].skillValue - retValue})`
            }

            return `(+${(this.values[index].skillValue - retValue).toFixed(2)})`
        },
        ShouldShowNextDescription() {
            return this.id != 0 && this.currentLevel > 0 && this.currentLevel < this.maxLevel;
        },
        ShouldShowRequirements() {
            return this.id != 0 && this.currentLevel >= 0 && this.currentLevel < this.maxLevel;
        },
        getValueAt(level) {
            if(level === 0){
                return 0;
            }

            if(!this.values){
                return 0;
            }

            var value = this.values.find(x => x.level == level)
            return value?.skillValue || 0;
        },
        getCurrentValue() {
            if(this.currentLevel === 0){
                return this.getNextValue()
            }

            if(!this.values){
                return 0;
            }

            var value = this.values.find(x => x.level == this.currentLevel)
            return value?.skillValue || 0;
        },
        getNextValue() {
            if(!this.values){
                return 0;
            }
            let value = this.values.find(x => x.level == this.currentLevel + this.requirements.points)
            return value?.skillValue || 0;
        },
        getSkillName(id) {
            return this.treeSkills.find(x => x.id === id)?.skillName;
        },
        getSkillRank(id) {
            return this.treeSkills.find(x => x.id === id)?.rank;
        },
        IsRankRequirementMet() {
            if(!this.requirements?.rank)
                return true;
            
            return this.treeSkills.filter(x => x.columnId === this.columnId && x.rank === this.requirements?.rank && x.currentLevel >= this.requirements.level).length > 0;
        },
        IsRequiredSkillRequirementMet() {
            if(!this.requirements.requiredSkillId)
                return true;
            var reqSkill1 = this.treeSkills.filter(x => x.id === this.requirements.requiredSkillId && x.currentLevel >= this.requirements.level).length > 0;
            if(!this.requirements.requiredSkillId_2)
                return reqSkill1;
            
            var reqSkill2 = this.treeSkills.filter(x => x.id === this.requirements.requiredSkillId_2 && x.currentLevel >= this.requirements.level).length > 0;
            return reqSkill1 && reqSkill2;
        },
        IsPointRequirementMet() {
          return this.requirements && this.requirements.points + this.treePoints <= 400;  
        },
        CanAddPoints() {
            if(!this.requirements)
                return true;

            ///this is so on max level build stays the same.
            if(this.treePoints === 400 && this.currentLevel > 0)
                return true;

            return this.IsRankRequirementMet() && this.IsRequiredSkillRequirementMet() && this.IsPointRequirementMet();
        },
        increaseCurrentLevel(e) {
            e.preventDefault();
            //Shift key functionality for +10 when points required are 1. (no effect on points required 10)
            var points = this.requirements && this.requirements.points ? this.requirements.points : 1;
            if(points === 1 && e.shiftKey)
            {
                points = 10;
            }

            //If I don't have enough free points, reduce it to the amount of points that I have left.
            if(this.treePoints + points > 400) {
                points = 400 - this.treePoints;
            }

            //If I would surpass the max level by adding the amount of points the user wants to add, I return.
            if(this.currentLevel + points > this.maxLevel)
            {
                points = this.maxLevel - this.currentLevel;
            }

            //If I do not meet the point requirements to add points, return.
            if(!this.IsPointRequirementMet())
            {
                this.$store.dispatch('addNotification', {
                    text: 'Requirements not met.',
                    withAction: false
                });
                return;   
            }

            //If I do not meet the rank requirements to add points, return.
            if(this.requirements && this.requirements.rank && !this.IsRankRequirementMet())
            {
                this.$store.dispatch('addNotification', {
                    text: 'Requirements not met.',
                    withAction: false
                });
                return;   
            }

            //If I do not meet the specific skill requirements to add points, return.
            if(this.requirements && this.requirements.requiredSkillId && !this.IsRequiredSkillRequirementMet())
            {
                this.$store.dispatch('addNotification', {
                    text: 'Requirements not met.',
                    withAction: false
                });
                return;   
            }

            let valueBefore = this.getValueAt(this.currentLevel);
            //Increment the currentLevel
            this.currentLevel += points;
            let currentValue = this.getValueAt(this.currentLevel) - valueBefore;
            this.increaseColumnLevel(points);
            //this.$emit('increaseColumnLevel', points)
            this.$store.commit('SetMasterTreeSkillLevel', { id: this.id, currentLevel: this.currentLevel });
            for (let i = 0; i < this.affectedStats.length; i++) {
                const statId = this.affectedStats[i];
                this.$store.commit('SetStatValue', { statId: statId, value: parseInt(currentValue) })
            }
        },
        decreaseCurrentLevel(e) {
            e.preventDefault();

            //Shift key functionality for -10 when points required are 1. (no effect on points required 10)
            var points = this.requirements?.points || 1;
            if(points === 1 && e.shiftKey)
            {
                points = 10;
            }

            //Prevent going below 0 currentLevel.
            if(this.currentLevel - points < 0)
            {
                points = this.currentLevel;
                if(points <= 0)
                    return;
            }

            //Prevent removal of points below 10 if other skill requires this skill to be >=10
            var skillsReq = this.treeSkills.filter(x => x.currentLevel > 0 && x.requiredSkillId === this.id && this.currentLevel - points < x.requiredLevel);
            if(this.requirements && skillsReq.length > 0)
            {
                this.$store.dispatch('addNotification', {
                    text: `${skillsReq[0]?.skillName} requires this skill to be over level 10.`,
                    withAction: false
                });
                return;
            }
  
            skillsReq = this.treeSkills.filter(x => x.currentLevel > 0 && x.requiredRank === this.rank && this.currentLevel - points < x.requiredLevel && this.columnId === x.columnId && this.treeSkills.filter(y => y && y.rank === x.requiredRank && y.currentLevel >= 10 && y.id != this.id && y.columnId === this.columnId).length === 0)
            //Prevent removal of points below 10 if other skills requires our rank to be >=10 and no other skill of our rank in our column is >=10
            if(this.requirements && skillsReq.length > 0)
            {
                this.$store.dispatch('addNotification', {
                    text: `${skillsReq[0]?.skillName} requires a rank ${this.rank} skill to be over level 10.`,
                    withAction: false
                });
                return;
            }

            //Decrement the currentLevel
            let valueBefore = this.getValueAt(this.currentLevel);
            this.currentLevel -= points;
            let currentValue = this.getValueAt(this.currentLevel) - valueBefore;

            this.decreaseColumnLevel(points);
            this.$store.commit('SetMasterTreeSkillLevel', { id: this.id, currentLevel: this.currentLevel });
            for (let i = 0; i < this.affectedStats.length; i++) {
                const statId = this.affectedStats[i];
                this.$store.commit('SetStatValue', { statId: statId, value: parseInt(currentValue) })
            }
        },
        GetDescription(value)
        {
            if(this.descriptionId && this.descriptionId != 0)
                return this.$t(`MasterTreeSkills.${this.descriptionId}`, { value: value })
            
            return this.description.replace("#value#", value)
        }
    }
}
</script>
 
<style scoped>
    .MasterTreeSkillWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .MasterTreeSkillBackground {
        background-image: url("../../../assets/mt400skillbg.png");
        display: flex;
        height: 60%;
        width: 80%;
        background-size: 100% 100%;
    }
    
    .MasterTreeSkillIcon {
        background-size: 100% 100%;
        width: 60%;
        height: 80%;
        margin-left: 7%;
        flex: 1 1 65%;
        align-self: center;
    }

    .RequirementsTitle {
        color:rgb(147, 201, 255);
    }

    .NextLevelTitle {
        color: rgb(106, 226, 160);
    }

    .RequirementNotMet {
        color: rgb(250, 63, 50);
    }

    .Gold {
        color: rgba(277, 165, 0);
    }

    .MasterTreeSkillTitle {
        color: rgba(277, 165, 0);
        overflow: hidden;
        white-space: nowrap;
    }

    .MasterTreeSkillIconDisabled {
        opacity: 0.25;
    }

    #MasterTreeSkillCounter {
        flex: 1 1 55%;
        text-align: center;
        color: rgba(211, 211, 211);
        align-self: flex-end;
    }

    .skill-tooltip-text {
        /* display: none; */
        /* visibility: hidden; */
        background-color: black;
        opacity: 0.90;
        color: #fff;
        text-align: center;
        padding: 5px 5px 5px 5px;
        margin-left: 60px;
        min-width: 280px;
        max-width: 330px;
        /* width: max-content; */
        border: 1px solid gray;;
        position: absolute;
        z-index: 1;        
        /* white-space: pre-wrap; */
    }

    .tooltipLeft {
        transform: translateX(calc(-100% - 75px));
    }

    .tooltipUp {
        transform: translateY(calc(-100% + 50px));
    }

    .tooltipUp.tooltipLeft {
        transform: translateY(calc(-100% + 50px)) translateX(calc(-100% - 75px));
    }

    .skillValueTable {
        flex: 1 1 50%;
        color: #fff;
    }
</style>