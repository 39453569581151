import StatConsts from '@/Formulas/StatConsts.js'

const level = 400;

function getMultiplier(stat) {
    const section1 = 1500;
    const section1Percentage = 10;
    const section2 = 2000;
    const section2Percentage = 30;
    const section3 = 3000;
    const section3Percentage = 45;

    var percentage = 1;
    if (stat <= section1) {
        percentage += (section1Percentage / section1) * stat / 100;
    }
    if (stat > section1 && stat <= section2) {
        percentage += section1Percentage / 100 + ((section2Percentage - section1Percentage) / (section2 - section1)) * (stat - section1) / 100;
    }
    if (stat > section2 && stat <= section3) {
        percentage += section2Percentage / 100 + ((section3Percentage - section2Percentage) / (section3 - section2)) * (stat - section2) / 100;
    }
    if (stat > section3) {
        percentage += section3Percentage / 100;
    }
    return percentage;
}

function calcMinAttackPower() {
    return parseInt(((this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 10 + (this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) / 8) * getMultiplier(this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) + this.CurrentStats[StatConsts.MinAttackPower]);
}

function calcMaxAttackPower() {
    return parseInt(((this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 6 + (this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) / 4) * getMultiplier(this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) + this.CurrentStats[StatConsts.MaxAttackPower]);
}

function calcAttackSuccessRate() {
    return parseInt(level * 5 + (this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) / 4 + (this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) * 1.25 + this.CurrentStats[StatConsts.AtkSuccRate])
}

function calcPvPAttackSuccessRate() {
    return parseInt(level * 3 + (this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) * 2.5 + this.CurrentStats[StatConsts.PvPAtkRate])
}

function calcDefense() {
    return parseInt((this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 7 * getMultiplier(this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) + this.CurrentStats[StatConsts.Defense])
}

function calcAttackSpeed() {
    return parseInt((this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 20 + this.CurrentStats[StatConsts.AttackSpeed]) + "/ 273"
}

function calcDefenseRate() {
    return parseInt((this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 4 * (this.CurrentStats[StatConsts.DefenseRate] / 100 + 1))
}

function calcPvPDefenseRate() {
    return parseInt((level * 2) + (this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 5 + this.CurrentStats[StatConsts.PvPDefRate])
}

function calcRetAttackPower() {
    return parseInt(97 + (this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) / 10) + this.CurrentStats[StatConsts.RetAtkPwr]
}

function calcRageAttackPower() {   
    return parseInt(97 + (this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 10) + this.CurrentStats[StatConsts.RageAtkPwr]
}

var GL = {
    Stats: [],
    calculate: function(statId) {
        if(!this.Stats[statId]) {
            return "?";
        }
        return this.Stats[statId].call(window.store.state.Planner);
    }
}

GL.Stats[StatConsts.MinAttackPower] = calcMinAttackPower;
GL.Stats[StatConsts.MaxAttackPower] = calcMaxAttackPower;
GL.Stats[StatConsts.AtkSuccRate] = calcAttackSuccessRate;
GL.Stats[StatConsts.PvPAtkRate] = calcPvPAttackSuccessRate;
GL.Stats[StatConsts.Defense] = calcDefense;
GL.Stats[StatConsts.AttackSpeed] = calcAttackSpeed;
GL.Stats[StatConsts.DefenseRate] = calcDefenseRate;
GL.Stats[StatConsts.PvPDefRate] = calcPvPDefenseRate;
GL.Stats[StatConsts.RetAtkPwr] = calcRetAttackPower;
GL.Stats[StatConsts.RageAtkPwr] = calcRageAttackPower;

export default GL;