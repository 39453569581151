<template>
  <div class="selectionWrapper">
    <div class="column-wrapper" v-if="classes && Object.keys(classes).length > 0">
      <template v-for="muclass in classes">
        <ExpandableColumn :key="muclass.id"
        :id="muclass.id"
        :name="$t(`ClassName.${muclass.id}`)"
        :code="muclass.code"
        :seasonData="muclass.seasonData"
        />
      </template>
    </div>
    <div class="ClassSelectAd" onclick="window.open('https://apps.microsoft.com/detail/muwatchdog/9NH7GP6FWTHH')"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ExpandableColumn from "./ExpandableColumn"

export default {
    computed: {
      ...mapState({
      classes: (state) => state.Planner.Classes,
      })
    },
    data: () => {
        return {
        }
    },
    components: {
      ExpandableColumn,
    },
}
</script>

<style>

.ClassSelectAd {
  background-image: url("../../assets/14.png");
  background-size: 100% 100%;
  width: 50%;
  height: 15%;
  flex: 1 0 15%;
  margin-top: 0.5%;
  max-width: 900px;
  max-height: 100px;
  cursor: pointer;
}

.column-wrapper {
  display: flex;
  width: 100%;
  flex: 1 0 100%;  
}

.selectionWrapper {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>