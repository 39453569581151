import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import routes from './router'
import store from './store';
import i18n from './plugins/i18n';
import vuetify from '@/plugins/vuetify';
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

//Router
Vue.use(VueRouter);

const router = new VueRouter({  
  mode: 'history',
  routes: routes,
});

//G-Tag Google Analytics
Vue.use(VueGtag, {
  config: { id: "G-J10XRBQ50Z" }
}, router);

window.mainApp = new Vue({
  el: "#app",
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
})//.$mount('#app')
