<template>
<div id="MasterTree">
    <div class="Header">
        <div class="Counter">
            Points Remaining: {{400 - this.count}} - Level required: {{400 + this.count}}
        </div>
    </div>
    <div class="MasterTreeColumnWrapper" v-if="Object.keys(tree).length > 0">
        <template v-for="(column, index) in tree.columns">
            <MasterTreeColumn :key="column.id"
                :id="column.id"
                :skills="column.skills"
                :order="index"
                @increaseTotalLevel='increaseTotalLevel'
                @decreaseTotalLevel='decreaseTotalLevel'
            />
        </template>
    </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import MasterTreeColumn from "./Components/MasterTreeColumn"

export default {
    computed: {
        ...mapState({
            tree: (state) => state.MasterTree.MasterTree,
            count: (state) => state.MasterTree.MasterTreePoints,
            selectedClassId: (state) => state.Planner.SelectedClassId,
            skills: (state) => state.MasterTree.MasterTreeSkills,
        })
    },
    data: () => {
        return {
            selectedClass: '',
        }
    },
    components: {
        MasterTreeColumn,
    },
    methods: {
        increaseTotalLevel(points) {
            this.$store.commit('SetMasterTreePoints', this.count + points);
        },
        decreaseTotalLevel(points) {
            this.$store.commit('SetMasterTreePoints', this.count - points);
        },
    }
}
</script>

<style scoped>
    .Counter {
        color:rgba(277, 165, 0);
        text-align: center;
    }

    #MasterTree {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .Header {
        display: flex;
        background-image: url("../../assets/Tree400Header.png");
        background-size: 100% 100%;
        justify-content: center;
        flex: 1 1 7%;
        align-items: center;
    }

    .MasterTreeColumnWrapper {
        display: flex;
        flex: 1 1 93%;
    }
</style>