// import Interceptor from '@/plugins/interceptor';

// const URL = `${process.env.VUE_APP_PLANNER_API_URL}`;
const Planner = {
  state: {
    Build: {},
    Stats: [],
    CurrentStats: [],
    showMasterTree: true,
    currentStrength: 0,
    currentAgility: 0,
    currentStamina: 0,
    currentEnergy: 0,
    currentCommand: 0,

    //Seasons
    Seasons: [],
    SelectedSeason: {},

    //Classes
    Classes: {},
    SelectedClassId: 0,
  },
  actions: {
    async Classes_getAllClasses({ commit }, season) {
      await fetch('/PlannerData/classes.json').then((res) => res.json()).then((json) => 
      {
        if(season.id >= 8) // 8 == 19.1
        {
          for (var i = 0; i < json.length; i++) {
            console.log(json[i])
            json[i].availableStats += season.extraPoints;
          }
        }
        commit('SetClasses', json.filter(x => x.seasonId <= season.id));
      });
    },
    // async Build_getBuild({ commit }, buildId) {
    //   const response = await Interceptor.get2(`${URL}/planner/getBuild?buildId=${buildId}`);
    //   commit('SetStats', response.data.stats);
    //   commit('SetBuild', response.data);
    // },
    // async Build_saveBuild(_, build) {
    //   const response = await Interceptor.post(`${URL}/planner/saveBuild`, build);
    //   return response;
    // },
    async Seasons_getAll({ commit }) {
      await fetch('/PlannerData/Seasons/seasons.json').then((res) => res.json()).then((json) => 
      {
        commit('SetSeasons', json);
        commit('SetSelectedSeasonById', json[1].id);
      });
    },
  },
  getters: {
    getStat: (state) => (statId) => {
      return state.CurrentStats[statId];
    },
    selectedClass: state => {
      return state.Classes.find(x => x.id === state.SelectedClassId);
    },
    showMasterTree: (state) => state.showMasterTree,
    selectedSeason: (state) => state.SelectedSeason,
  },
  mutations: {
    //Class
    SetClasses(state, value) {
      state.Classes = value;
    },
    SetSelectedClassId(state, value) {
      state.SelectedClassId = value;
    },

    //Seasons
    SetSeasons(state, value) {
      state.Seasons = value;
    },
    SetSelectedSeason(state, value) {
      state.SelectedSeason = value;
    },
    SetSelectedSeasonById(state, value) {
      state.SelectedSeason = state.Seasons.filter(x => x.id == value)[0] || state.Seasons[state.Seasons.length - 1];
    },
    
    //Stats
    SetStats(state, value) {
      state.Stats = [];
      state.CurrentStats = [];
      for (let i = 0; i < value.length; i++) {
        state.Stats[value[i].id] = value[i];
        state.CurrentStats[value[i].id] = 0;
      }
    },
    SetStatValue(state, statValue) {
      if(statValue && statValue.statId){
        state.CurrentStats.splice(statValue.statId, 1, state.CurrentStats[statValue.statId] + statValue.value);
      }
    },
    SetBuild(state, value) {
      state.Build = value; 
      // master tree
      for (let i = 0; i < state.Build.masterTree.columns.length; i++) {
        const column = state.Build.masterTree.columns[i];
        for (let j = 0; j < column.skills.length; j++) {
          const skill = column.skills[j];
          for (let i = 0; i < skill.affectedStats.length; i++) {
            const statId = skill.affectedStats[i];
            if(skill.currentLevel > 0)
              state.CurrentStats.splice(statId, 1, parseInt(skill.values[skill.currentLevel - 1]?.skillValue))
          }
        }
      }

      // enhanced tree passives
      for (let i = 0; i < state.Build.skillEnhancementTree.passiveSkills.length; i++) {
        const skill = state.Build.skillEnhancementTree.passiveSkills[i].passiveSkill;
        for (let i = 0; i < skill.affectedStats.length; i++) {
          const statId = skill.affectedStats[i];
          if(skill.currentLevel > 0)
            state.CurrentStats.splice(statId, 1, state.CurrentStats[statId] + parseInt(skill.values[skill.currentLevel - 1]?.skillValue))
        }     
      }
    },
    ResetStats(state) {
      for (let i = 0; i < state.CurrentStats.length; i++) {
        //state.CurrentStats[i] = state.Stats[state.CurrentStats[i].id].baseValue;
      }
    },
    SetShowMasterTree(state) {
      state.showMasterTree = true;
    },
    SetShowSkilLEnhancement(state) {
      state.showMasterTree = false;
    },
    SetStrength(state, value) {
      state.currentStrength = value;
    },
    SetAgility(state, value) {
      state.currentAgility = value;
    },
    SetStamina(state, value) {
      state.currentStamina = value;
    },
    SetEnergy(state, value) {
      state.currentEnergy = value;
    },
    SetCommand(state, value) {
      state.currentCommand = value;
    }
  },
}

export default Planner;