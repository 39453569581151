<template>
    <div class="MasterTreeSkillWrapper">
        <div class="MasterTreeSkillBackground"
            v-bind:style="[{backgroundImage: 'url(../../../' + this.border }, this.hidden ? {'visibility': 'hidden'} : {'visbility': 'visible'}]"
        >
            <div 
                class="skill-tooltip MasterTreeSkillIcon" 
                v-bind:class="[!CanAddPoints() ? disabled : '']"
                @contextmenu="decreaseCurrentLevel($event)" 
                v-on:click="increaseCurrentLevel($event)"
                v-bind:style="{ backgroundImage: 'url(../../../imgs/4th_skill_icon_' + this.iconId.toString().padStart(2, '0') + '.png)' }"
                @mouseenter="focus = true"
                @mouseleave="focus = false"
            />
                <span class="skill-tooltip-text"
                    v-if="focus"
                    v-bind:class="[ this.tooltipUp ? 'tooltipUp' : '', this.tooltipLeft ? 'tooltipLeft' : '' ]"
                >
                    <span class="MasterTreeSkillTitle">{{this.name}}</span><br>
                    {{$t("SkillEnhancementTree.SkillGrade", { currentLevel: currentLevel, maxLevel: maxLevel })}}<br>
                    {{this.GetDescription(this.getCurrentValue)}} 

                    <div v-if="focus && this.$store.getters.getCtrlPressed" style="display: flex;">
                        <div class="skillValueTable">
                            <div v-for="(level, index) in values" :key="index">
                                <div v-if="index < maxLevel / 2">
                                    <span v-bind:class="[level.level === currentLevel ? 'Gold' : '']">Level {{level.level}}: {{level.skillValue}}<br></span>
                                </div>
                            </div> 
                        </div>
                        <div class="skillValueTable">
                            <div v-for="(level, index) in values" :key="index">
                                <div v-if="index >= maxLevel / 2">
                                    <span v-bind:class="[level.level === currentLevel ? 'Gold' : '']">Level {{level.level}}: {{level.skillValue}}<br></span>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div v-else>
                        <span v-if="ShouldShowNextDescription()">
                            <br>
                            <span class="NextLevelTitle">{{$t(`MasterTree.MasterTreeSkillNextLevel`)}}</span><br>
                            <span>{{this.GetDescription(this.getNextValue)}}</span>
                        </span>              
                        <div v-if="ShouldShowRequirements()"> 
                            <br>
                            <span class="RequirementsTitle">{{$t("MasterTree.MasterTreeSkillRequirements")}}</span><br>
                            <span
                                v-bind:class="[!IsPointRequirementMet() ? 'RequirementNotMet' : '']"
                            >
                                {{$t("MasterTree.PointRequirement", { points: this.requirements ? this.requirements.points : 0 })}}
                            </span>
                            <br>
                            <span 
                                v-if="this.requirements.requiredSkillId"
                                v-bind:class="[!IsRequiredSkillRequirementMet() ? 'RequirementNotMet' : '']"
                            >
                                {{$t("SkillEnhancementTree.SkillRequirement", { level: this.requirements.level, skillName: getSkillName(this.requirements.requiredSkillId, true) })}}
                            </span>
                            <span 
                                v-if="this.requirements.requiredSkillId_2"
                                v-bind:class="[!IsRequiredSkillRequirementMet() ? 'RequirementNotMet' : '']"
                            >
                                <br>{{$t("SkillEnhancementTree.SkillRequirement", { level: this.requirements.level, skillName: getSkillName(this.requirements.requiredSkillId_2) })}}
                            </span>
                        </div>
                    </div>                
                </span>
            <div id="MasterTreeSkillCounter">
                {{this.currentLevel}}
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            tree400Skills: (state) => state.MasterTree.MasterTreeSkills,
            treeSkills: (state) => state.MasterTree.SkillEnhancementSkills,
            treePassives: (state) => state.MasterTree.SkillEnhancementPassives,
            treePoints: (state) => state.MasterTree.SkillEnhancementTreePoints,
            skillEnhancementTreeSkillLinePoints: (state) => state.MasterTree.SkillEnhancementTreeSkillLinePoints,
            skillEnhancementTreeDebuffPoints: (state) => state.MasterTree.SkillEnhancementTreeDebuffPoints,
            selectedSeason: (state) => state.Planner.SelectedSeason,
        })
    },
    created() {
        if(this.id != 0) {
            if(this.currentLevelFromBuild != 0) {
                this.currentLevel = this.currentLevelFromBuild;
                this.increaseTotalLevel(this.currentLevelFromBuild)
                if(this.increaseTypePoints)
                    this.increaseTypePoints(this.currentLevelFromBuild)
            }
            this.$store.commit('AddSkillEnhancementSkill', { id: this.id, currentLevel: this.currentLevel, requiredSkillId: this.requirements.requiredSkillId, requiredSkillId_2: this.requirements.requiredSkillId_2, requiredLevel: this.requirements.level, skillName: this.name, pageId: this.pageId });
        }
    },
    data: function() {
        return {
            disabled: 'MasterTreeSkillIconDisabled',
            currentLevel: 0,
            focus: false
        }
    },
    props: {
        increaseTotalLevel: {
            type: Function
        },
        decreaseTotalLevel: {
            type: Function
        },
        increaseTypePoints: {
            type: Function
        },
        decreaseTypePoints: {
            type: Function
        },
        id: {
            type: Number,
            Default: 0
        },
        name: {
            type: String,
            Default: ""
        },
        maxLevel: {
            type: Number,
            Default: 20
        },
        hidden: {
            type: Boolean,
            Default: false
        },
        requirements: {
            tpye: Object,
            Default: {}
        },
        values: {
            tpye: Object,
            Default: {}
        },
        currentLevelFromBuild: {
            type: Number,
            Default: 0
        },
        iconId: {
            type: Number,
            Default: 0
        },
        affectedStats: {
            type: Array,
            Default: []
        },
        tooltipUp: {
            type: Boolean,
            default: false
        },
        tooltipLeft: {
            type: Boolean,
            default: false
        },
        border: {
            type: String
        },
        mainDebuff1Id: {
            type: Number,
            default: 0
        },
        mainDebuff2Id: {
            type: Number,
            default: 0
        },
        pageId: {
            type: Number,
            default: 0
        },
        type: {
            type: String,
            default: ''
        },
        isSkillRequiredFromMasterTree: {
            type: Boolean,
            default: false
        },
        description: {
            Type: String,
            default: ""
        }
    },
    methods: { 
        ShouldShowNextDescription() {
            return this.id != 0 && this.currentLevel > 0 && this.currentLevel < this.maxLevel;
        },
        ShouldShowRequirements() {
            return this.id != 0 && this.currentLevel >= 0 && this.currentLevel < this.maxLevel;
        },
        getCurrentValue() {
            if(this.currentLevel === 0){
                return this.getNextValue()
            }

            if(!this.values){
                return 0;
            }

            var value = this.values.find(x => x.level == this.currentLevel)

            return value?.skillValue || 0;
        },
        getNextValue() {
            if(!this.values){
                return 0;
            }
            let value = this.values.find(x => x.level == this.currentLevel + this.requirements.points)
            return value?.skillValue || 0;
        },
        getSkillName(id, lookInTree400 = false) {
            if(lookInTree400 && this.isSkillRequiredFromMasterTree && this.tree400Skills)  
                return this.tree400Skills.find(x => x.id === id).skillName;
            else
                return this.treeSkills[this.pageId].find(x => x.id === id).skillName;
        },
        IsRequiredSkillRequirementMet() {
            if(!this.requirements.requiredSkillId)
                return true;
            
            var reqSkill1 = undefined;
            if(this.isSkillRequiredFromMasterTree && this.tree400Skills)
                reqSkill1 = this.tree400Skills.filter(x => x.id === this.requirements.requiredSkillId && x.currentLevel >= this.requirements.level).length > 0;
            else
                reqSkill1 = this.treeSkills[this.pageId].filter(x => x.id === this.requirements.requiredSkillId && x.currentLevel >= this.requirements.level && x.pageId === this.pageId).length > 0;
            
            if(this.mainDebuff1Id != 0) {
                var reqNotUpped1 = this.treeSkills[this.pageId].filter(x => x.id === this.mainDebuff1Id && x.currentLevel === 0 && x.pageId === this.pageId).length > 0;
                if(!reqNotUpped1) {
                    return false;
                }
            }
            
            if(this.mainDebuff2Id != 0) {
                var reqNotUpped2 = this.treeSkills[this.pageId].filter(x => x.id === this.mainDebuff2Id && x.currentLevel === 0 && x.pageId === this.pageId).length > 0;
                if(!reqNotUpped2) {
                    return false;
                }
            }
            
            if(!this.requirements.requiredSkillId_2)
                return reqSkill1;
            
            var reqSkill2 = this.treeSkills[this.pageId].filter(x => x.id === this.requirements.requiredSkillId_2 && x.currentLevel >= this.requirements.level && x.pageId === this.pageId).length > 0;
            return reqSkill1 && reqSkill2;
        },
        IsPointRequirementMet() {
          return this.requirements && this.requirements.points + this.treePoints <= (this.selectedSeason.maxLevel - 800);  
        },
        CanAddPoints() {
            if(!this.requirements)
                return true;

            ///this is so on max level build stays the same.
            if(this.treePoints === (this.selectedSeason.maxLevel - 800) && this.currentLevel > 0)
                return true;

            return this.IsRequiredSkillRequirementMet() && this.IsPointRequirementMet();
        },
        increaseCurrentLevel(e) {
            e.preventDefault();

            //Shift key functionality for +10 when points required are 1. (no effect on points required 10)
            var points = this.requirements && this.requirements.points ? this.requirements.points : 1;
            if(points === 1 && e.shiftKey)
            {
                points = 10;
            }

            //If I don't have enough free points, reduce it to the amount of points that I have left.
            if(this.treePoints + points > (this.selectedSeason.maxLevel - 800)) {
                points = (this.selectedSeason.maxLevel - 800) - this.treePoints;
            }

            //If I would surpass the max level by adding the amount of points the user wants to add, I return.
            if(this.currentLevel + points > this.maxLevel)
            {
                points = this.maxLevel - this.currentLevel;
            }


            //If I do not meet the point requirements to add points, return.
            if(this.requirements && points + this.treePoints > (this.selectedSeason.maxLevel - 800))
            {
                this.$store.dispatch('addNotification', {
                    text: 'Requirements not met.',
                    withAction: false
                });
                return;   
            }

            //If I do not meet the specific skill requirements to add points, return.
            if(this.requirements && this.requirements.requiredSkillId && !this.IsRequiredSkillRequirementMet())
            {
                this.$store.dispatch('addNotification', {
                    text: 'Requirements not met.',
                    withAction: false
                });
                return;   
            }
            
            //Increment the currentLevel
            this.currentLevel += points;
            this.increaseTotalLevel(points);
            if(this.increaseTypePoints)
                this.increaseTypePoints(points);
            this.$store.commit('SetSkillEnhancementSkillLevel', { id: this.id, currentLevel: this.currentLevel, pageId: this.pageId });
            // this.$store.commit('SetStatValue', { statId: this.affectedStat?.id, value: this.currentLevel === 0 ? 0 : this.getCurrentValue() })
        },
        decreaseCurrentLevel(e) {
            e.preventDefault();

            //Shift key functionality for -10 when points required are 1. (no effect on points required 10)
            var points = this.requirements?.points || 1;
            if(points === 1 && e.shiftKey)
            {
                points = 10;
            }

            //Prevent going below 0 currentLevel.
            if(this.currentLevel - points < 0)
            {
                points = this.currentLevel;
                if(points <= 0)
                    return;
            }

            //Prevent removal of points below 10 if other skill requires this skill to be >=10
            var skillReq = this.treeSkills[this.pageId].filter(x => x.currentLevel > 0 && x.requiredSkillId === this.id && this.currentLevel - points < x.requiredLevel && x.pageId === this.pageId);
            if(this.requirements && skillReq.length > 0)
            {
                this.$store.dispatch('addNotification', {
                    text: `${skillReq[0].skillName} requires this skill to be over level 10.`,
                    withAction: false
                });
                return;
            }

            if(this.type == 'skill')
            {
                //Calc points to check against
                var newTypePoints = this.skillEnhancementTreeSkillLinePoints - points;
                var foundPassives = this.treePassives.filter(x => !x.isDebuffPassive && ((x.currentLevel > 20 && newTypePoints < x.tier3PassiveRequiredPoints) || (x.currentLevel > 10 && newTypePoints < x.tier2PassiveRequiredPoints) || (x.currentLevel > 0 && newTypePoints < x.tier1PassiveRequiredPoints)))
                if(foundPassives.length > 0) {
                    this.$store.dispatch('addNotification', {
                        text: `${foundPassives[0].name} requires Skill Enhancement Points provided by this skill.`,
                        withAction: false
                    });
                    return;
                }

            } 
            else if(this.type == 'debuff') {
                //Calc points to check against
                newTypePoints = this.skillEnhancementTreeDebuffPoints - points;
                foundPassives = this.treePassives.filter(x => x.isDebuffPassive && ((x.currentLevel > 20 && newTypePoints < x.tier3PassiveRequiredPoints) || (x.currentLevel > 10 && newTypePoints < x.tier2PassiveRequiredPoints) || (x.currentLevel > 0 && newTypePoints < x.tier1PassiveRequiredPoints)))
                if(foundPassives.length > 0) {
                    this.$store.dispatch('addNotification', {
                        text: `${foundPassives[0].name} requires Debuff Enhancement Points provided by this skill.`,
                        withAction: false
                    });
                    return;
                }
            }   

            //Decrement the currentLevel
            this.currentLevel -= points;
            this.decreaseTotalLevel(points);
            if(this.increaseTypePoints)
                this.decreaseTypePoints(points);
            this.$store.commit('SetSkillEnhancementSkillLevel', { id: this.id, currentLevel: this.currentLevel, pageId: this.pageId });
            // this.$store.commit('SetStatValue', { statId: this.affectedStat?.id, value: this.currentLevel === 0 ? 0 : this.getCurrentValue() })
            // this.$emit('decreaseTotalLevel', points)
            // this.$emit('decreaseTypePoints', points)
            // this.$store.commit('SetSkillEnhancementSkillLevel', { id: this.id, currentLevel: this.currentLevel, pageId: this.pageId });
            // this.$store.commit('SetStatValue', { statId: this.affectedStat?.id, value: this.currentLevel === 0 ? 0 : this.getCurrentValue() })
        },
        GetDescription(value)
        {
            if(this.description && this.description != "")
                return this.description.replace("#value#", value)
            return this.$t(`SkillEnhancementSkills.${this.id}`, { value: value })
        }
    }
}
</script>

<style scoped>
    .MasterTreeSkillWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 14.28%;
    }

    .MasterTreeSkillBackground {
        background-image: url("../../../assets/mt400skillbg.png");
        display: flex;
        height: 60%;
        width: 75%;
        background-size: 100% 100%;
    }

    .MasterTreeSkillIcon {
        background-size: 100% 100%;
        width: 60%;
        height: 80%;
        margin-left: 7%;
        flex: 1 1 65%;
        align-self: center;
    }

    .RequirementsTitle {
        color:rgb(147, 201, 255);
    }

    .NextLevelTitle {
        color: rgb(106, 226, 160);
    }

    .RequirementNotMet {
        color: rgb(250, 63, 50);
    }

    .MasterTreeSkillTitle {
        color: rgba(277, 165, 0);
        overflow: hidden;
        white-space: nowrap;
    }

    .MasterTreeSkillIconDisabled {
        opacity: 0.25;
    }

    #MasterTreeSkillCounter {
        flex: 1 1 55%;
        text-align: center;
        color: rgba(211, 211, 211);
        align-self: flex-end;
    }


    .Gold {
        color: rgba(277, 165, 0);
    }

    .skill-tooltip-text {
        /* display: none; */
        /* visibility: hidden; */
        background-color: black;
        opacity: 0.90;
        color: #fff;
        text-align: center;
        padding: 5px 5px 5px 5px;
        margin-left: 60px;
        /* width: max-content; */
        border: 1px solid gray;;
        position: absolute;
        z-index: 1;        
        white-space: pre-wrap;
    }

    .tooltipLeft {
        transform: translateX(calc(-100% - 75px));
    }

    .tooltipUp {
        transform: translateY(calc(-100% + 50px));
    }

    .tooltipUp.tooltipLeft {
        transform: translateY(calc(-100% + 50px)) translateX(calc(-100% - 75px));
    }

    .skillValueTable {
        flex: 1 1 50%;
        color: #fff;
    }
</style>