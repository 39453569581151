<template>
<div class="ClassStatsWindowWrapper">
    <div class="ClassStatsWindowHeader">
        <div class="Title Gold">
            {{$t(`Class.StatsLabel`)}}
        </div>
    </div>
    <div class="ClassStatsWindow">
        <div class="ButtonsWrapper">
            <div 
                class="TreeLever"
                v-on:click="toggleMaster(true)"
            >
                <div class="Gold Title Centered">
                    Master
                </div>
            </div>
            <div 
                class="TreeLever"
                v-on:click="toggleMaster(false)"
            >
                <div class="Gold Title Centered">
                    Skill Enhancement
                </div>
            </div>
        </div>
        <div class="SubWindowWrapper">
            <div class="SubWindowTop"/>
            <div class="SubWindow">
                <div class="statLine">
                    <div class="leftLine">{{$t(`Class.ClassLabel`)}}</div>
                    <div class="rightLine Title">{{$t(`ClassName.${this.selectedClass.id}`)}}</div>
                </div>
                <div class="statLine">
                    <div class="leftLine">{{$t(`Class.LevelLabel`)}}</div>
                    <div class="rightLine">{{this.selectedSeason.maxLevel}}</div>
                </div>
                <div class="statLine">
                    <div class="leftLine">{{$t(`Class.PointsRemainingLabel`)}}</div>
                    <div class="rightLine skill-tooltip">
                        {{this.currentRemainingPoints}}
                        <span class="skill-tooltip-text">
                            {{$t(`Class.remainingPointsFromLeveling1.${this.remainingPointsLevel1to220}`)}}:<span class="Gold"> {{this.remainingPointsLevel1to220}}</span><br>
                            <div v-if="this.selectedClass.availableStats === 2365 || this.selectedClass.availableStats === 2565">
                                {{$t("Class.remainingPointsFromLeveling2")}}:<span class="Gold"> {{this.remainingPointsLevel220to400}}</span><br>
                                {{$t("Class.remainingPointsFromQuest150")}}:<span class="Gold"> {{this.remainingPointsQuest150}}</span><br>
                            </div>
                                {{$t("Class.remainingPointsFromQuest400")}}:<span class="Gold"> {{this.remainingPointsQuest400}}</span><br>
                                {{$t("Class.remainingPointsFromQuest800")}}:<span class="Gold"> {{this.remainingPointsQuest800}}</span><br> 
                            <div v-if="this.selectedSeason.id >= 8">
                                {{$t("Class.remainingPointsFromQuest1200")}}:<span class="Gold"> {{this.remainingPointsQuest1200}}</span><br>
                            </div>
                                {{$t("Class.remainingPointsTotal")}}:<span class="Gold"> {{this.selectedClass.availableStats}}</span><br>
                        </span>
                    </div>
                </div>
                <div class="statLine">
                    <div class="leftLine">{{$t(`Class.FruitCreateLabel`)}}</div>
                    <div class="addableStat">
                        <div 
                            class="AddStatButton"
                            @contextmenu="decreaseFruits($event)" 
                            v-on:click="increaseFruits($event)"
                        />
                        <div class="rightLine">{{this.currentFruits}}/{{this.selectedClass.maxFruits}}</div>
                    </div>
                </div>
            </div>
            <div class="SubWindowBottom"/>
        </div>

        <!-- <br/> -->

        
        <div class="SubWindowWrapper">
            <div class="SubWindowTop"/>
            <div class="SubWindow">
                <!-- Strength -->
                <div class="statLine mainStat">
                    <div class="mainStatLeftLine">{{$t(`Class.StrengthLabel`)}}</div>
                    <div
                        class="AddStatButton"
                        @contextmenu="decreaseStrength($event)" 
                        v-on:click="increaseStrength($event)"/>
                    <div class="rightLine skill-tooltip">
                        {{this.currentStrength + strengthFromTree400}}
                        <span class="skill-tooltip-text">
                            {{$t("Class.StatTooltipBase")}} {{$t(`Class.StrengthLabel`)}}:<span class="Gold"> {{this.selectedClass.strength}}</span><br>
                            {{$t("Class.StatTooltipFromAddingPoints")}}:<span class="Gold"> {{this.currentStrength - this.selectedClass.strength}}</span><br>
                            {{$t("Class.StatTooltipFromTree400")}}:<span class="Gold"> {{this.strengthFromTree400}}</span><br>
                        </span>
                    </div>
                    <div class="bonusStats Blue">{{additionalStrength == 0 ? '-' : `(+${additionalStrength})`}}</div>
                </div>
                
                <!-- <div class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* Attack Power</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.MinAttackPower)}} ~ {{calcStat(statConsts.MaxAttackPower)}}
                    </div>
                    <div class="bonusStats"></div>
                </div>
                
                <div class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* Atk Succ rate</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.AtkSuccRate)}}
                    </div>
                    <div class="bonusStats"></div>
                </div>
                
                <div class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* PvP Atk rate</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.PvPAtkRate)}}
                    </div>
                    <div class="bonusStats"></div>
                </div> -->

                <!-- Agility -->
                <div class="statLine mainStat">
                    <div class="mainStatLeftLine">{{$t(`Class.AgilityLabel`)}}</div>
                    <div
                        class="AddStatButton"
                        @contextmenu="decreaseAgility($event)" 
                        v-on:click="increaseAgility($event)"
                    />
                    <div class="rightLine skill-tooltip">
                        {{this.currentAgility + this.agilityFromTree400}}
                        <span class="skill-tooltip-text">
                            {{$t("Class.StatTooltipBase")}} {{$t(`Class.AgilityLabel`)}}:<span class="Gold"> {{this.selectedClass.agility}}</span><br>
                            {{$t("Class.StatTooltipFromAddingPoints")}}:<span class="Gold"> {{this.currentAgility - this.selectedClass.agility}}</span><br>
                            {{$t("Class.StatTooltipFromTree400")}}:<span class="Gold"> {{this.agilityFromTree400}}</span><br>
                        </span>
                    </div>
                    <div class="bonusStats Blue">{{additionalAgility == 0 ? '-' : `(+${additionalAgility})`}}</div>
                </div>

                
                <!-- <div class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* Defense</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.Defense)}}
                    </div>
                    <div class="bonusStats"></div>
                </div>
                
                <div class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* Attack Speed</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.AttackSpeed)}}
                    </div>
                    <div class="bonusStats"></div>
                </div>
                
                <div class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* Defense rate</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.DefenseRate)}}
                    </div>
                    <div class="bonusStats"></div>
                </div>
                
                <div class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* PvP Def rate</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.PvPDefRate)}}
                    </div>
                    <div class="bonusStats"></div>
                </div> -->


                <!-- Stamina -->
                <div class="statLine mainStat">
                    <div class="mainStatLeftLine">{{$t(`Class.StaminaLabel`)}}</div>
                    <div 
                        class="AddStatButton"
                        @contextmenu="decreaseStamina($event)" 
                        v-on:click="increaseStamina($event)"
                    />
                    <div class="rightLine skill-tooltip">
                        {{this.currentStamina + this.staminaFromTree400}}
                        <span class="skill-tooltip-text">
                            {{$t("Class.StatTooltipBase")}} {{$t(`Class.StaminaLabel`)}}:<span class="Gold"> {{this.selectedClass.stamina}}</span><br>
                            {{$t("Class.StatTooltipFromAddingPoints")}}:<span class="Gold"> {{this.currentStamina - this.selectedClass.stamina}}</span><br>
                            {{$t("Class.StatTooltipFromTree400")}}:<span class="Gold"> {{this.staminaFromTree400}}</span><br>
                        </span>
                    </div>
                    <div class="bonusStats Blue">{{additionalStamina == 0 ? '-' : `(+${additionalStamina})`}}</div>
                </div>
                
                <div v-if="hasStat(statConsts.PrxAtkPwr)" class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* Prx Atk Pwr(%)</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.PrxAtkPwr)}}%
                    </div>
                    <div class="bonusStats Gold"></div>
                </div>

                <!-- Energy -->
                <div class="statLine mainStat">
                    <div class="mainStatLeftLine">{{$t(`Class.EnergyLabel`)}}</div>
                    <div 
                        class="AddStatButton"
                        @contextmenu="decreaseEnergy($event)" 
                        v-on:click="increaseEnergy($event)"
                    />
                    <div class="rightLine skill-tooltip">
                        {{this.currentEnergy + this.energyFromTree400}}
                        <span class="skill-tooltip-text">
                            {{$t("Class.StatTooltipBase")}} {{$t(`Class.EnergyLabel`)}}:<span class="Gold"> {{this.selectedClass.energy}}</span><br>
                            {{$t("Class.StatTooltipFromAddingPoints")}}:<span class="Gold"> {{this.currentEnergy - this.selectedClass.energy}}</span><br>
                            {{$t("Class.StatTooltipFromTree400")}}:<span class="Gold"> {{this.energyFromTree400}}</span><br>
                        </span>
                    </div>
                    <div class="bonusStats Blue">{{additionalEnergy == 0 ? '-' : `(+${additionalEnergy})`}}</div>
                </div>
                <!-- <div v-if="hasStat(statConsts.MinWizardryDmg)" class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* Wizardry Dmg</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.MinWizardryDmg)}} ~ {{calcStat(statConsts.MaxWizardryDmg)}}
                    </div>
                    <div class="bonusStats Gold">{{calcStat(statConsts.AdditionalWizardryDmg)}}</div>
                </div>

                <div v-if="hasStat(statConsts.SkillAtkPwr)" class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* Skill Atk Pwr(%)</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.SkillAtkPwr)}}%
                    </div>
                    <div class="bonusStats"></div>
                </div>

                <div v-if="hasStat(statConsts.MinCurseDmg)" class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* Curse Dmg</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.MinCurseDmg)}} ~ {{calcStat(statConsts.MaxCurseDmg)}}
                    </div>
                    <div class="bonusStats Gold">{{calcStat(statConsts.AdditionalCurseDmg)}}</div>
                </div>
                
                <div v-if="hasStat(statConsts.DivAtkPwr)" class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* Div Atk Pwr(%)</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.DivAtkPwr)}}%
                    </div>
                    <div class="bonusStats Gold"></div>
                </div>
                
                <div v-if="hasStat(statConsts.AOEAtkPwr)" class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* AOE Atk Pwr(%)</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.AOEAtkPwr)}}%
                    </div>
                    <div class="bonusStats Gold"></div>
                </div>
                
                <div v-if="hasStat(statConsts.RetAtkPwr)" class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* Ret Atk Pwr(%)</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.RetAtkPwr)}}%
                    </div>
                    <div class="bonusStats Gold"></div>
                </div>
                
                <div v-if="hasStat(statConsts.RageAtkPwr)" class="statLine smallStatLine">
                    <div class="mainStatLeftLine">* Rage Atk Pwr(%)</div>
                    <div class="rightLine">
                        {{calcStat(statConsts.RageAtkPwr)}}%
                    </div>
                    <div class="bonusStats Gold"></div>
                </div> -->

                <!-- Command -->
                <div class="statLine mainStat" v-if="this.currentCommand > 0">
                    <div class="mainStatLeftLine">{{$t(`Class.CommandLabel`)}}</div>
                    <div 
                        class="AddStatButton"
                        @contextmenu="decreaseCommand($event)" 
                        v-on:click="increaseCommand($event)"
                    />
                    <div class="rightLine skill-tooltip">
                        {{this.currentCommand + this.commandFromTree400}}
                        <span class="skill-tooltip-text">
                            {{$t("Class.StatTooltipBase")}} {{$t(`Class.CommandLabel`)}}:<span class="Gold"> {{this.selectedClass.command}}</span><br>
                            {{$t("Class.StatTooltipFromAddingPoints")}}:<span class="Gold"> {{this.currentCommand - this.selectedClass.command}}</span><br>
                            {{$t("Class.StatTooltipFromTree400")}}:<span class="Gold"> {{this.commandFromTree400}}</span><br>
                        </span>
                    </div>
                    <div class="bonusStats Blue">{{additionalCommand == 0 ? '-' : `(+${additionalCommand})`}}</div>
                </div>
            </div>
            <div class="SubWindowBottom"/>
            
            <!-- <SaveBuildModal
                :dialog="dialog"
                :currentFruits="this.currentFruits"
                @closeDialog="closeDialog"
            /> -->
        </div>
        
    </div>
    <HelpModal
        :dialog="dialog"
        @closeDialog="closeDialog"
    />  
        <div class="StatsAd" onclick="window.open('https://apps.microsoft.com/detail/muwatchdog/9NH7GP6FWTHH')" >
        </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
// import SaveBuildModal from '@/Components/SaveBuildModal.vue';
import HelpModal from '@/Components/HelpModal.vue';
import StatConsts from '@/Formulas/StatConsts.js'
import Formulas from '@/Formulas/Formulas';

export default {
    data: function() {
        return {
            selectedClass: {},
            className: '',
            currentFruits: 0,
            currentRemainingPoints: 0,
            dialog: false,
            remainingPointsLevel1to220: 0,
            remainingPointsLevel220to400: 0,
            remainingPointsQuest150: 0,
            remainingPointsQuest400: 70,
            remainingPointsQuest800: 100,
            remainingPointsQuest1200: 200,
            statConsts: StatConsts,
        }
    },
    props: {
        isBuild: {
            type: Boolean,
            default: false
        }
    },
    components: {
        // SaveBuildModal,
        HelpModal,
    },
    computed: {
        ...mapState({
            selectedSeason: (state) => state.Planner.SelectedSeason,
            selectedClassId: (state) => state.Planner.SelectedClassId,
            classes: (state) => state.Planner.Classes,
            build: (state) => state.Planner.Build,
            currentStats: (state) => state.Planner.CurrentStats,
            strengthFromTree400: (state) => state.Planner.CurrentStats[4],
            agilityFromTree400: (state) => state.Planner.CurrentStats[3],
            staminaFromTree400: (state) => state.Planner.CurrentStats[2],
            energyFromTree400: (state) => state.Planner.CurrentStats[1],
            commandFromTree400: (state) => state.Planner.CurrentStats[5],
            additionalStrength: (state) => state.Planner.CurrentStats[9],
            additionalAgility: (state) => state.Planner.CurrentStats[8],
            additionalStamina: (state) => state.Planner.CurrentStats[7],
            additionalEnergy: (state) => state.Planner.CurrentStats[6],
            additionalCommand: (state) => state.Planner.CurrentStats[10],
            currentStrength: (state) => state.Planner.currentStrength,
            currentAgility: (state) => state.Planner.currentAgility,
            currentStamina: (state) => state.Planner.currentStamina,
            currentEnergy: (state) => state.Planner.currentEnergy,
            currentCommand: (state) => state.Planner.currentCommand,
        })
    },
    async mounted() {
        //Load class into var
        this.selectedClass = this.$store.getters.selectedClass
        this.className = this.selectedClass.name;

        //Set remaining points
        this.remainingPointsLevel1to220 = this.selectedClass.availableStats === 2365 || this.selectedClass.availableStats === 2565 ? 1095 : 2793;
        this.remainingPointsLevel220to400 = this.selectedClass.availableStats === 2365 || this.selectedClass.availableStats === 2565 ? 1080 : 0;
        this.remainingPointsQuest150 = this.selectedClass.availableStats === 2365 || this.selectedClass.availableStats === 2565 ? 20 : 0;

        if(this.isBuild) {
            //Set stats
            this.$store.commit('SetStrength', this.build.assignedStrength);
            this.$store.commit('SetAgility', this.build.assignedAgility);
            this.$store.commit('SetStamina', this.build.assignedStamina);
            this.$store.commit('SetEnergy', this.build.assignedEnergy);
            this.$store.commit('SetCommand', this.build.assignedCommand);
            this.$store.commit('SetStatValue', { statId: 9, value: this.build.assignedAdditionalStrength });
            this.$store.commit('SetStatValue', { statId: 8, value: this.build.assignedAdditionalAgility });
            this.$store.commit('SetStatValue', { statId: 7, value: this.build.assignedAdditionalStamina });
            this.$store.commit('SetStatValue', { statId: 6, value: this.build.assignedAdditionalEnergy });
            //Calculate remaining points
            this.currentRemainingPoints = 
                //Base
                this.selectedClass.availableStats - 
                //Assigned
                (this.currentStrength - this.selectedClass.strength) - 
                (this.currentAgility - this.selectedClass.agility) -  
                (this.currentStamina - this.selectedClass.stamina) -  
                (this.currentEnergy - this.selectedClass.energy) -  
                (this.currentCommand - this.selectedClass.command) + 
                //Fruits
                this.build.fruitsUsed
            //Set Fruits
            this.currentFruits = this.build.fruitsUsed;
        } else {
            //Set base stats
            this.$store.commit('SetStrength', this.selectedClass.strength);
            this.$store.commit('SetAgility', this.selectedClass.agility);
            this.$store.commit('SetStamina', this.selectedClass.stamina);
            this.$store.commit('SetEnergy', this.selectedClass.energy);
            this.$store.commit('SetCommand', this.selectedClass.command ? this.selectedClass.command : 0);
            //Set default remaining points
            this.currentRemainingPoints = this.selectedClass.availableStats;
        }
    },
    methods: {    
        hasStat(statId) {
            return this.currentStats[statId] != undefined
        },
        calcStat(statId) {
            return Formulas.calculate(statId)
        },
        closeDialog() {
            this.dialog = false
        },
        increaseFruits(e) {
            e.preventDefault();
            if (this.selectedClass.maxFruits === this.currentFruits)
                return;

            var points = 1;
            if (e.shiftKey) {
                points = this.selectedClass.maxFruits - this.currentFruits;
            }

            this.currentRemainingPoints += points;
            this.currentFruits += points;
        },
        decreaseFruits(e) {
            e.preventDefault();
            if (this.currentFruits === 0)
                return;

            var points = 1;
            if (e.shiftKey) {
                points = this.currentFruits;
            }

            if (this.currentRemainingPoints - points < 0)
                return;

            this.currentRemainingPoints -= points;  
            this.currentFruits -= points;
        },
        increaseStrength(e) {
            e.preventDefault();
            if (this.currentRemainingPoints === 0)
                return;
            
            var points = 1;
            if (e.shiftKey) {
                points = this.currentRemainingPoints < 100 ? this.currentRemainingPoints : 100;
            }
            
            if (e.ctrlKey) {
                points = this.currentRemainingPoints < 10 ? this.currentRemainingPoints : 10;
            }

            if (this.currentRemainingPoints - points < 0)
                return;

            this.currentRemainingPoints -= points;
            this.$store.commit('SetStrength', this.currentStrength + points);
        },
        decreaseStrength(e) {
            e.preventDefault();
            if (this.currentStrength === this.selectedClass.strength)
                return;
            
            var points = 1;
            if (e.shiftKey) {
                points = this.currentStrength < 100 ? (this.currentStrength - this.selectedClass.strength) : 100;
            }

            if (e.ctrlKey) {
                points = this.currentStrength < 10 ? (this.currentStrength - this.selectedClass.strength) : 10;
            }

            if (this.currentStrength - points < this.selectedClass.strength)
                return;
            
            this.$store.commit('SetStrength', this.currentStrength - points);
            this.currentRemainingPoints += points;
        },
        increaseAgility(e) {
            e.preventDefault();
            if (this.currentRemainingPoints === 0)
                return;
            
            var points = 1;
            if (e.shiftKey) {
                points = this.currentRemainingPoints < 100 ? this.currentRemainingPoints : 100;
            }

            if (e.ctrlKey) {
                points = this.currentRemainingPoints < 10 ? this.currentRemainingPoints : 10;
            }

            if (this.currentRemainingPoints - points < 0)
                return;

            this.currentRemainingPoints -= points;
            this.$store.commit('SetAgility', this.currentAgility + points);
        },
        decreaseAgility(e) {
            e.preventDefault();
            if (this.currentAgility === this.selectedClass.agility)
                return;
            
            var points = 1;
            if (e.shiftKey) {
                points = this.currentAgility < 100 ? (this.currentAgility - this.selectedClass.agility) : 100;
            }
            if (e.ctrlKey) {
                points = this.currentAgility < 10 ? (this.currentAgility - this.selectedClass.agility) : 10;
            }

            if (this.currentAgility - points < this.selectedClass.agility)
                return;
            
            this.$store.commit('SetAgility', this.currentAgility - points);
            this.currentRemainingPoints += points;
        },
        increaseStamina(e) {
            e.preventDefault();
            if (this.currentRemainingPoints === 0)
                return;
            
            var points = 1;
            if (e.shiftKey) {
                points = this.currentRemainingPoints < 100 ? this.currentRemainingPoints : 100;
            }

            if (e.ctrlKey) {
                points = this.currentRemainingPoints < 10 ? this.currentRemainingPoints : 10;
            }

            if (this.currentRemainingPoints - points < 0)
                return;

            this.currentRemainingPoints -= points;
            this.$store.commit('SetStamina', this.currentStamina + points);
        },
        decreaseStamina(e) {
            e.preventDefault();
            if (this.currentStamina === this.selectedClass.stamina)
                return;
            
            var points = 1;
            if (e.shiftKey) {
                points = this.currentStamina < 100 ? (this.currentStamina - this.selectedClass.stamina) : 100;
            }
            if (e.ctrlKey) {
                points = this.currentStamina < 10 ? (this.currentStamina - this.selectedClass.stamina) : 10;
            }

            if (this.currentStamina - points < this.selectedClass.stamina)
                return;
            
            this.$store.commit('SetStamina', this.currentStamina - points);
            this.currentRemainingPoints += points;
        },
        increaseEnergy(e) {
            e.preventDefault();
            if (this.currentRemainingPoints === 0)
                return;
            
            var points = 1;
            if (e.shiftKey) {
                points = this.currentRemainingPoints < 100 ? this.currentRemainingPoints : 100;
            }

            if (e.ctrlKey) {
                points = this.currentRemainingPoints < 10 ? this.currentRemainingPoints : 10;
            }

            if (this.currentRemainingPoints - points < 0)
                return;

            this.currentRemainingPoints -= points;
            this.$store.commit('SetEnergy', this.currentEnergy + points);
        },
        decreaseEnergy(e) {
            e.preventDefault();
            if (this.currentEnergy === this.selectedClass.energy)
                return;
            
            var points = 1;
            if (e.shiftKey) {
                points = this.currentEnergy < 100 ? (this.currentEnergy - this.selectedClass.energy) : 100;
            }
            if (e.ctrlKey) {
                points = this.currentEnergy < 10 ? (this.currentEnergy - this.selectedClass.energy) : 10;
            }

            if (this.currentEnergy - points < this.selectedClass.energy)
                return;
            
            this.$store.commit('SetEnergy', this.currentEnergy - points);
            this.currentRemainingPoints += points;
        },
        increaseCommand(e) {
            e.preventDefault();
            if (this.currentRemainingPoints === 0)
                return;
            
            var points = 1;
            if (e.shiftKey) {
                points = this.currentRemainingPoints < 100 ? this.currentRemainingPoints : 100;
            }

            if (e.ctrlKey) {
                points = this.currentRemainingPoints < 10 ? this.currentRemainingPoints : 10;
            }

            if (this.currentRemainingPoints - points < 0)
                return;

            this.currentRemainingPoints -= points;
            this.$store.commit('SetCommand', this.currentCommand + points);
        },
        decreaseCommand(e) {
            e.preventDefault();
            if (this.currentCommand === this.selectedClass.command)
                return;
            
            var points = 1;
            if (e.shiftKey) {
                points = this.currentCommand < 100 ? (this.currentCommand - this.selectedClass.command) : 100;
            }
            if (e.ctrlKey) {
                points = this.currentCommand < 10 ? (this.currentCommand - this.selectedClass.command) : 10;
            }

            if (this.currentCommand - points < this.selectedClass.command)
                return;
            
            this.$store.commit('SetCommand', this.currentCommand - points);
            this.currentRemainingPoints += points;
        },
        toggleMaster(master) {
            if(master) {
                this.$store.commit('SetShowMasterTree')
            } else {
                this.$store.commit('SetShowSkilLEnhancement')
            }
        }
    }
}
</script>

<style scoped>
    .SubWindowWrapper {
        margin-left: 0.5%;
    }

    .SubWindowBottom {
        background-image: url("../../assets/ClassStatsBackgroundBottom.png");
        background-size: 100% 100%;
        height: 1vh;
        width: 100%;
    }

    .SubWindowTop {
        background-image: url("../../assets/ClassStatsBackgroundTop.png");
        background-size: 100% 100%;
        height: 1vh;
        width: 100%;
    }

    .SubWindow {
        background-image: url("../../assets/ClassStatsBackgroundCenter.png");
        background-size: 100% 100%;
        width: 100%;
        padding-left: 3.5%;
        padding-top: 3%;
        padding-bottom: 3%;
        display: flex;
        flex-direction: column;
    }

    .ClassStatsWindowWrapper {
        display: flex;
        flex: 10;
        flex-direction: column;
    }

    .ClassStatsWindowHeader {
        display: flex;
        background-image: url("../../assets/ClassStatsHeader.png");
        background-size: 100% 100%;
        align-items: center;
        justify-content: center;
        flex: 0 1 7%;
    }

    .ClassStatsWindow {
        background: url("../../assets/ClassStats.png");
        background-size: 100% 100%;
        padding: 5% 5% 5% 4%;
        flex: 0 1 50%;
    }

    .StatsAd {
        background-image: url("../../assets/13.png");
        background-size: 100% 100%;
        flex: 0 0 35%;
        max-width: 355px;
        max-height: 250px;
        cursor: pointer;
    }

    .Gold {
        color:rgba(277, 165, 0);
    }

    .Blue {
        color: cornflowerblue;
    }

    .Title {
        text-align: center;
    }

    .Centered {
        align-items: center;
        flex: 1 1 auto;
    }

    .statLine {
        display: flex;
        padding-left: 1%;
        margin-right: 3.5%;
        margin-bottom: 1%;
    }

    .leftLine {
        color: #b9b9b9;
        flex: 1 1 20%;
    }

    .rightLine {
        color:rgba(277, 165, 0);
        flex: 1 1 auto;
        width: 25%;
        text-align: left;
    }

    .bonusStats {
        text-align: end;
        flex: 1 1 auto;
        width: 25%;
        padding-right: 5%;
    }

    .mainStat {
        background-color: rgba(125, 125, 125, 0.1);
    }

    .mainStatLeftLine {
        color: #b9b9b9;
        flex: 1 1 auto;
        width: 50%;
    }

    .subStat {
        font-size: 12px;
    }

    .addableStat {
        display: flex;
        flex: 1 1 25%;
    }

    .ButtonsWrapper {
        height: 4.5vh;
        display: flex;
        padding: 0% 0% 0% 1%;
    }

    .TreeLever {
        background-image: url("../../assets/TreeLeverNormal.png");
        align-self: center;
        margin-right: 1%;
        background-size: 100% 100%;
        flex: 1 1 45%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .TreeLever:hover {
        background-image: url("../../assets/TreeLeverHover.png");
    }

    .TreeLever:active {
        background-image: url("../../assets/TreeLeverClicked.png");
    }

    .TreeLever-Disabled {
        background-image: url("../../assets/TreeLeverDisabled.png");
        align-self: center;
        margin-right: 1%;
        background-size: 100% 100%;
        flex: 1 1 45%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .AddStatButton {
        background-image: url("../../assets/AddStatButtonIdle.png");
        background-size: 100% 100%;
        height: 19px;
        width: 19px;
        align-self: center;
        margin-right: 1%;
    }

    .AddStatButton:hover {
        background-image: url("../../assets/AddStatButtonHover.png");
    }

    .AddStatButton:active {
        background-image: url("../../assets/AddStatButtonPressed.png");
    }

    .skill-tooltip-text {
        visibility: hidden;
        background-color: black;
        opacity: 0.90;
        color: #fff;
        padding: 5px 5px 5px 5px;
        margin-left: 25px;
        max-width: 330px;
        border: 1px solid gray;;
        position: absolute;
        z-index: 1;
        
        
    }

    .skill-tooltip:hover > .skill-tooltip-text {
        visibility: visible;
    }

    .smallStatLine {
        font-size: small;
    }

</style>