<template>
<div id="Tree">
    <div class="Header">
        <div class="Gold Centered">
            Points Remaining: {{selectedSeason.maxLevel - 800 - count}} - Level required: {{800 + count}}
        </div>
    </div>
    <div class="flex SkillEnhancementTreeWrapper">
        <div class="PageSide">
            <div class="ExplosionToggleWrapper">
                <div 
                    v-bind:class="[showExplosion ? 'ExplosionToggleButton DarkGray' : 'ExplosionToggleButton DarkGray Selected']"
                    v-on:click="toggleExplosion(false)"
                >
                    Normal Skill
                </div>
                <div 
                    v-bind:class="[showExplosion ? 'ExplosionToggleButton DarkGray Selected' : 'ExplosionToggleButton DarkGray']"
                    class="ExplosionToggleButton DarkGray"
                    v-on:click="toggleExplosion(true)"
                >
                    Explosion Skill
                </div>
            </div>
            
            <div
                v-bind:class="[!showExplosion ? 'Hidden ExplosionWrapper' : 'ExplosionWrapper']" 
            >
                <SkillEnhancementExplosionPage 
                    :explosionSkills="tree.explosionSkills"
                    :increaseTotalLevel='increaseTotalLevel'
                    :decreaseTotalLevel='decreaseTotalLevel'
                />
            </div>
            <div
                class="page"
                v-for="(page, index) in tree.pages"
                v-bind:class="[selectedPage != index || showExplosion ? 'Hidden' : '']"
                v-bind:key="index"
                @wheel.prevent="handleScroll($event)"
            >
                <SkillEnhancementPage 
                    :key="index"
                    :id="index + 1"
                    :skillLineSkills="page.skillLineSkills"
                    :debuffLine1Skills="page.debuffLine1Skills"
                    :debuffLine2Skills="page.debuffLine2Skills"
                    :additionalDebuffSkills="page.additionalDebuffSkills"
                    :mainDebuff1Id="page.mainDebuff1Id"
                    :mainDebuff2Id="page.mainDebuff2Id"
                    :increaseTotalLevel='increaseTotalLevel'
                    :decreaseTotalLevel='decreaseTotalLevel'
                    :increaseSkillLinePoints='increaseSkillLinePoints'
                    :decreaseSkillLinePoints='decreaseSkillLinePoints'
                    :increaseDebuffPoints='increaseDebuffPoints'
                    :decreaseDebuffPoints='decreaseDebuffPoints'
                />
            </div>
            <div 
                v-bind:class="[showExplosion ? 'pageSelectorWrapper Hidden' : 'pageSelectorWrapper']"
            >
                <div    
                  class="pageDownButton"
                  v-on:click="pageDown($event)"
                />
                <div class="pageSelectorText Gold">{{selectedPage + 1}}/{{tree.pages.length}}</div>
                <div 
                    class="pageUpButton"
                    v-on:click="pageUp($event)"
                />
            </div>
        </div>
        <div class="PassiveSide">
            <SkillEnhancementPassive 
                :skills="tree.passiveSkills"
                :increaseTotalLevel='increaseTotalLevel'
                :decreaseTotalLevel='decreaseTotalLevel'
            />
        </div>
    </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import SkillEnhancementPage from './Components/SkillEnhancementPage.vue';
import SkillEnhancementExplosionPage from './Components/SkillEnhancementExplosionPage.vue';
import SkillEnhancementPassive from './Components/SkillEnhancementPassive.vue';

export default {
    computed: {
        ...mapState({
            tree: (state) => state.MasterTree.SkillEnhancementTree,
            selectedClassId: (state) => state.Planner.SelectedClassId,
            count: (state) => state.MasterTree.SkillEnhancementTreePoints,
            skillEnhancementTreeSkillLinePoints: (state) => state.MasterTree.SkillEnhancementTreeSkillLinePoints,
            skillEnhancementTreeDebuffPoints: (state) => state.MasterTree.SkillEnhancementTreeDebuffPoints,
            selectedSeason: (state) => state.Planner.SelectedSeason,
        })
    },
    data: () => {
        return {
            selectedClass: '',
            selectedPage: 0,
            showExplosion: false
        }
    },
    components: {
        SkillEnhancementPage,
        SkillEnhancementExplosionPage,
        SkillEnhancementPassive
    },
    methods: {
        increaseTotalLevel(points) {
            this.$store.commit('SetSkillEnhancementPoints', this.count + points);
        },
        decreaseTotalLevel(points) {
            this.$store.commit('SetSkillEnhancementPoints', this.count - points);
        },
        increaseSkillLinePoints(points) {
            this.$store.commit('SetSkillEnhancementSkillLinePoints', this.skillEnhancementTreeSkillLinePoints + points);
        },
        decreaseSkillLinePoints(points) {
            this.$store.commit('SetSkillEnhancementSkillLinePoints', this.skillEnhancementTreeSkillLinePoints - points);
        },
        increaseDebuffPoints(points) {
            this.$store.commit('SetSkillEnhancementDebuffLinePoints', this.skillEnhancementTreeDebuffPoints + points);
        },
        decreaseDebuffPoints(points) {
            this.$store.commit('SetSkillEnhancementDebuffLinePoints', this.skillEnhancementTreeDebuffPoints - points);
        },
        pageDown(e) {
            if (this.selectedPage == 0)
                return;

            if (e && e.shiftKey) {
                this.selectedPage = 0;
                return;
            }

            this.selectedPage--;            
        },
        pageUp(e) {
            if (this.selectedPage + 1 == this.tree.pages.length)
                return;

            if (e && e.shiftKey) {
                this.selectedPage = this.tree.pages.length;
                return;
            }

            this.selectedPage++;
        },
        handleScroll(e) {
            e.deltaY > 0 ? this.pageUp() : this.pageDown();
        },
        toggleExplosion(toExplosion) {
            this.showExplosion = toExplosion;
        }
    },
}
</script>

<style scoped>

    .Gray {
      color: rgba(211, 211, 211);
    }

    .DarkGray {
      color: darkgray;
    }
    .SkillEnhancementTreeWrapper {
        flex: 1 1 93%;
    }

    .flex {
        display: flex;
    }

    .PageSide {
        flex: 1 1 66.7%;
        background: url("../../assets/background-center.png");
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
    }

    .PassiveSide {
        flex: 1 1 33.3%;
        width: 33.3%;
        background: url("../../assets/background-center.png");
        background-size: 100% 100%;
        padding: 0.25% 1.5% 3% 1%;
    }

    .Gold {
        color:rgba(277, 165, 0);
    }

    .Centered {
        text-align: center;
    }
    
    #Tree {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .Header {
        display: flex;
        background-image: url("../../assets/Tree400Header.png");
        background-size: 100% 100%;
        justify-content: center;
        flex: 1 1 7%;
        align-items: center;
    }

    .ButtonsWrapper {
        height: 4.5vh;
        display: flex;
        padding: 0% 0% 0% 1%;
    }

    .page {
        flex: 1 1 89%;
    }
    
    .pageDownButton {
      background-image: url("../../assets/pageDownButton-Idle.png");
      background-size: 100% 100%;
      height: 19px;
      width: 19px;
      align-self: center;
    }

    .pageDownButton-Disabled {
      background-image: url("../../assets/pageDownButton-Disabled.png");
      background-size: 100% 100%;
      height: 19px;
      width: 19px;
      align-self: center;
    }

    .pageDownButton:hover {
      background-image: url("../../assets/pageDownButton-Hover.png");
    }

    .pageDownButton:active {
      background-image: url("../../assets/pageDownButton-Pressed.png");
    }

    .pageUpButton {
      background-image: url("../../assets/pageUpButton-Idle.png");
      background-size: 100% 100%;
      height: 19px;
      width: 19px;
      align-self: center;
    }

    .pageUpButton-Disabled {
      background-image: url("../../assets/pageUpButton-Disabled.png");
      background-size: 100% 100%;
      height: 19px;
      width: 19px;
      align-self: center;
    }

    .pageUpButton:hover {
      background-image: url("../../assets/pageUpButton-Hover.png");
    }

    .pageUpButton:active {
      background-image: url("../../assets/pageUpButton-Pressed.png");
    }

    .pageSelectorWrapper {
        display: flex;
        justify-content: center;
        flex: 0 1 8%;
        align-items: center;
    }

    .pageSelectorText {
        margin-right: 2%;
        margin-left: 2%;
    }

    .ExplosionToggleWrapper {
        display: flex;
        padding-top: 3%;
        padding-left: 4%;
        flex: 0 1 7%;
    }

    .ExplosionToggleButton {
        background-image: url("../../assets/explosionSwap-Idle.png");
        background-size: 100% 100%;
        width: 25%;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .ExplosionToggleButton:hover {
        background-image: url("../../assets/explosionSwap-Hover.png");
    }

    .Selected {
        background-image: url("../../assets/explosionSwap-Hover.png");
    }


    .Hidden {
        display: none;
    }

    .ExplosionWrapper {
        height: 40%;
    }
</style>