<template>
<div class="loading-wrapper">
    <div class="PlannerWrapper" v-if="!loading">
        <StepperTutorial
            :dialog="dialog"
            @closeDialog="closeDialog"
        />
        <ClassStats 
            :isBuild="buildId !== undefined"
        />
        <div class="TreeWrapper">
            <div v-bind:class="[ this.$store.getters.showMasterTree ? 'Tree' : 'hidden']">
                <MasterTree />
            </div>
            <div v-bind:class="[ !this.$store.getters.showMasterTree ? 'Tree' : 'hidden']">
                <SkillEnhancementTree />
            </div>
        </div>
        <!-- <Inventory /> -->
    </div>
    <div class="loading" v-else>
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        <span class="loading-text">{{$t(`Planner.LoadingText.${(Math.floor(Math.random() * 10))}`)}}</span>
    </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import MasterTree from "@/pages/MasterTree/MasterTree";
import SkillEnhancementTree from "@/pages/SkillEnhancementTree/SkillEnhancementTree";
import ClassStats from "@/pages/ClassStats/ClassStats";
// import Inventory from "@/pages/Inventory/Inventory";
import StepperTutorial from "@/Components/StepperTutorial";

export default {
    computed: {
        ...mapState({
            selectedClassId: (state) => state.Planner.SelectedClassId,
            selectedSeason: (state) => state.Planner.SelectedSeason,
            count: (state) => state.MasterTree.MasterTreePoints,
            tree800count: (state) => state.MasterTree.SkillEnhancementTreePoints,
            build: (state) => state.Planner.Build,
            skills: (state) => state.MasterTree.MasterTreeSkills,
            tree800skills: (state) => state.MasterTree.SkillEnhancementSkills,
            tree800passives: (state) => state.MasterTree.SkillEnhancementPassives,
        })
    },
    data: () => ({ 
        loading: true, 
        dialog: true,
    }),
    props: {
        buildId: {
            type: String,
            default: undefined
        }
    },
    components: {
        MasterTree,
        ClassStats,
        StepperTutorial,
        SkillEnhancementTree,
        // Inventory
    },
    created() {
        this.$store.commit('SetShowMasterTree')
        if(this.buildId) {
            //Dont show stepper tutorial
            this.dialog = false
            //Load stats from backend
            // this.$store.dispatch('planner_getStats', this.selectedClassId);
            //Load build from backend
            this.$store.dispatch('Build_getBuild', this.buildId)
            .then(() => {
                this.$store.dispatch('Classes_getAllClasses', this.build.seasonId)
                .then(() => {
                    this.$store.commit('SetSelectedSeason', this.build.season);
                    this.$store.commit('SetMasterTree', this.build.masterTree);
                    this.$store.commit('SetSelectedClassId', this.build.classId);
                    this.$store.commit('SetSkillEnhancementTree', this.build.skillEnhancementTree);
                    this.loading = false;
                });
            })
            .catch((err) => {
                console.error(err);
                this.$store.dispatch('addNotification', {
                    text: 'Error fetching build.',
                    withAction: false
                });
                this.$router.push('/');
            });
        } else {
            //Load Tree400 / Tree800
            this.$store.dispatch('MasterTree_getMasterTree', { classId: this.selectedClassId, seasonId: this.selectedSeason.id })
            .then(() => {
                if(this.count !== 0 || this.tree800count !== 0) {
                    if(this.tree800count !== 0) {
                        this.$store.commit('SetSkillEnhancementPoints', 0);
                    }
                    if(this.count !== 0) {
                        this.$store.commit('SetMasterTreePoints', 0);
                    }
                    this.$store.commit('ResetStats')
                }
                if(this.skills !== [])
                    this.$store.commit('SetMasterTreeSkills', []);
                if(this.tree800skills !== [])
                    // this.$store.commit('SetSkillEnhancementSkills', []);
                    this.$store.commit('SetSkillEnhancementSkillLinePoints', 0);
                    this.$store.commit('SetSkillEnhancementDebuffLinePoints', 0);
                if(this.tree800passives !== [])
                    this.$store.commit('SetSkillEnhancementPassives', []);
                this.loading = false;
            });
        }
        //If I dont have a buildId and no class is selected, push home.
        if(!this.buildId && this.selectedClassId === 0) {
            this.$router.push('/');
            return;
        }

    },
    mounted() {
        var that = this;
        window.addEventListener('keydown', function(ev) {        
            that.onKeyDown(ev); 
        });
        window.addEventListener('keyup', function(ev) {
            that.onKeyUp(ev); 
        });
    },
    methods: {
        onKeyDown(e) {
            if(e.key === "Control" && !this.$store.getters.getCtrlPressed) {
                this.$store.commit('SetCtrlPressed');        
            }
        },
        onKeyUp(e) {
            if(e.key === "Control" && this.$store.getters.getCtrlPressed) {
                this.$store.commit('SetCtrlUnpressed');    
            }
        },
        closeDialog() {
            this.dialog = false
        }
    },
}
</script>

<style>

    .PlannerWrapper {
        display: flex;
        justify-content: center;
        margin-top: 2vh;
        margin-bottom: 2vh;
        min-width: 1335px;
    }

    .hidden {
        display:none;
    }

    .TreeWrapper {
        flex: 25;
        height: 100%;
    }

    .Tree {
        height: 100%;
    }

</style>