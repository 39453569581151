const notifications = {
    state: {
      list: []
    },
    actions: {
      addNotification({ commit }, notification) {
        commit('addNotification', notification);
      },
      removeNotification({ commit }, guid) {
        commit('removeNotification', guid);
      }
    },
    mutations: {
      addNotification(state, notification) {
        const item = notification;
        item.guid = `${new Date().getTime()}`;
        state.list.push(notification);
      },
      removeNotification(state, guid) {
        const idx = state.list.findIndex((notification) => notification.guid === guid);
        state.list.splice(idx, 1);
      }
    }
  };
  
  export default notifications;
  