<template>
    <div class="SubWindowWrapper">
        <div class="SubWindowTop"/>
        <div class="SubWindow">
            <template v-for="(skill, index) in explosionSkills">
                <SkillEnhancementSkill :key="index"
                    :id="skill.id"
                    :maxLevel="skill.maxLevel"
                    :hidden="skill.id === 0"
                    :name="$t(`SkillEnhancemnetSkillNames.${skill.id}`)"
                    :requirements="skill.requirements"
                    :values="skill.values"
                    :currentLevelFromBuild="skill.currentLevel"
                    :iconId="skill.iconId"
                    :affectedStats="skill.affectedStats"
                    :border="'mt800skillbg_red.png'"
                    :pageId="0"
                    :increaseTotalLevel='increaseTotalLevel'
                    :decreaseTotalLevel='decreaseTotalLevel'
                />
            </template>
        </div>
        <div class="SubWindowBottom"/>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import SkillEnhancementSkill from "./SkillEnhancementSkill"

export default {
    computed: {
        ...mapState({
            tree: (state) => state.MasterTree.SkillEnhancementTree
        })
    },
    data: () =>{
        return {
            count: 0,
        }
    },
    props: {
        increaseTotalLevel: {
            type: Function
        },
        decreaseTotalLevel: {
            type: Function
        },
        id: {
            type: Number,
            default: 0
        },
        explosionSkills: {
            type: Array
        },
    },
    components: {
        SkillEnhancementSkill,
    },
    methods: {
    }
}
</script>

<style scoped>
    .SubWindowBottom {
        background-image: url("../../../assets/ClassStatsBackgroundBottom.png");
        background-size: 100% 100%;
        height: 1vh;
        width: 100%;
    }

    .SubWindowTop {
        background-image: url("../../../assets/ClassStatsBackgroundTop.png");
        background-size: 100% 100%;
        height: 1vh;
        width: 100%;
    }

    .SubWindow {
        background-image: url("../../../assets/ClassStatsBackgroundCenter.png");
        background-size: 100% 100%;
        padding: 1% 3.5% 0% 3.5%;
        display: flex;
        height: 100%;
        flex-wrap: wrap;
    }

    .SubWindowWrapper {        
        padding: 0vh 1vh 1vh 1vh;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

</style>