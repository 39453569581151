import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Languages json
import KR from '@/languages/KR/translations.json';
import EN from '@/languages/EN/translations.json';
// import Formatter from './formatter'

// Detect language navigator
let languageNavigator = navigator.language.split('-')[0].toUpperCase();

// Validate navigator
if (languageNavigator !== 'EN' && languageNavigator !== 'KR') {
  languageNavigator = 'EN';
}
// const formatter = new Formatter({ languageNavigator })

Vue.use(VueI18n);

export default new VueI18n({
  locale: languageNavigator,
  //formatter: formatter,
  fallbackLocale: 'EN',
  messages: {
    KR,
    EN
  }
});