<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="700"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title>
          Changelog
        </v-card-title>
        <v-card-text>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-divider/> 
                    <div class="overline mb-4">
                        - 1.2.62
                    </div>
                    <v-list-item-subtitle class="ms-4">Added Season 19.2 (KR)</v-list-item-subtitle><br>
                </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-divider/> 
                    <div class="overline mb-4">
                        - 1.2.61
                    </div>
                    <v-list-item-subtitle class="ms-4">Added Season 19.1</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Added extra available points from level 1200 5th class quest.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Season selector is now ordered newest to oldest.</v-list-item-subtitle><br>
                </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-divider/> 
                    <div class="overline mb-4">
                        - 1.2.60
                    </div>
                    <v-list-item-subtitle class="ms-4">Added Season 18.2</v-list-item-subtitle><br>
                </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-divider/> 
                    <div class="overline mb-4">
                        - 1.2.59
                    </div>
                    <v-list-item-subtitle class="ms-4">Fixed various errors in values in 18.1 tree only.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Added Banner space.</v-list-item-subtitle><br>
                </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-divider/> 
                    <div class="overline mb-4">
                        - 1.2.58
                    </div>
                    <v-list-item-subtitle class="ms-4">Removed formulated stats since they were not being updated anyway.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Removed save build button until further notice.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Class names now show up in class selection screen.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed Gun Crusher's Ice Blast Enhancement Skill line.</v-list-item-subtitle><br>
                </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-divider/> 
                    <div class="overline mb-4">
                        - 1.2.57
                    </div>
                    <v-list-item-subtitle class="ms-4">Added Season S18.1</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Added Illusion Knight's skill trees.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed Lemuria's Master Tree column names.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed Magic Gladiator's Wing of Ruin Atk PowUp rank requirements.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed a bug in which loading a build that had no points added in the Master Tree would fail.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed a bug in which the wrong skills would be saved when saving S17.2 Elf builds.</v-list-item-subtitle><br>
                </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-divider/> 
                    <div class="overline mb-4">
                        - 1.2.56
                    </div>
                    <v-list-item-subtitle class="ms-4">Added Season S17.2</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Elf reworked skill trees updated.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed Kundun's Cloak of Splendor Defense PowUp description.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed Kundun's Cloak of Splendor Attack PowUp description.</v-list-item-subtitle><br>
                </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-divider/> 
                    <div class="overline mb-4">
                        - 1.2.55
                    </div>
                    <v-list-item-subtitle class="ms-4">Fixed RF's skills that were not merged correctly into 1.2.53+ version.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Magic Mastery now affects stats.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Added Season 17.1.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Added White Wizard skill trees.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Added Mage: Lemuria skill trees.</v-list-item-subtitle><br>
                </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-divider/> 
                    <div class="overline mb-4">
                        - 1.2.54
                    </div>
                    <v-list-item-subtitle class="ms-4">Fixed MG's Two-Handed Staff Mastery values.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed MG's Two-Handed Sword Mastery values.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed MG's Fire Slash Mastery requeriments.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed GC's Increase Stat master tree values.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed Sum's Darkness Mastery values.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed all classes' Vengenace values.</v-list-item-subtitle><br>
                </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-divider/> 
                    <div class="overline mb-4">
                        - 1.2.53
                    </div>
                    <v-list-item-subtitle class="ms-4">Added help dialog with useful shortcuts.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Added stats to C window.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed SM's Evil Spirit Strengthner.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Made it so Master Tree Skills affect stats.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Normalized text size across entire site.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed a bit the resizing issue. Fully responsive site still WIP.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Rewrote big part of how the backend works. If I did my job right, you should not notice anything different in terms of site functionality.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Changed initial selected season to always be GMO's current season.</v-list-item-subtitle><br>
                </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-divider/> 
                    <div class="overline mb-4">
                        - 1.1.52
                    </div>
                    <v-list-item-subtitle class="ms-4">Improved Skill Enhancement Tree's performance substantially.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed a bug in which builds would not load correct season.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Added Season 16.2 changes.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed some of Gun Crusher's Skill data.</v-list-item-subtitle><br>
                </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-divider/> 
                    <div class="overline mb-4">
                        - 1.1.51
                    </div>
                    <v-list-item-subtitle class="ms-4">Added season selector.</v-list-item-subtitle><br>
                </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-divider/> 
                    <div class="overline mb-4">
                        - 1.1.50
                    </div>
                    <v-list-item-subtitle class="ms-4">Fixed a bug in which Skill Enhancement skills would wrongly take skills from other pages into account when increassing/decreasing. Reported by @Aviv, @Darce</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed a bug in which Passive Skills would not fit into the border correctly.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed a few Gun Crusher's Master Tree skill descriptions.</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed a bug in which Skill Enhancement Tree would break on the last 100 points. Reported by @Darce</v-list-item-subtitle><br>
                    <v-list-item-subtitle class="ms-4">Fixed a bug in which Skill Enhancement Tree's 2nd Debuff would sometimes fail to save.</v-list-item-subtitle><br>
                </v-list-item-content>
            </v-list-item>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data () {
      return {

      }
    },
    props: {
        dialog: {
            Type: Boolean,
            Default: false,
        },
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
  }
</script>

<style>

::-webkit-scrollbar {
  width: 0.5em;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: rgb(41, 41, 41);
}
</style>