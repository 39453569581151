import BM from './BM'
import SM from './SM'
import ELF from './ELF'
import MG from './MG'
import DL from './DL'
import SUM from './SUM'
import RF from './RF'
import GL from './GL'
import RW from './RW'
import SL from './SL'
import GC from './GC'
import WW from './WW'
import LM from './LM'

var Season17Part1 = {
    Classes: [],
    calculate: function(statId) {
        return this.Classes[window.store.state.Planner.SelectedClassId].calculate(statId);
    }
}

Season17Part1.Classes[1] = BM;
Season17Part1.Classes[2] = SM;
Season17Part1.Classes[3] = ELF;
Season17Part1.Classes[4] = MG;
Season17Part1.Classes[5] = DL;
Season17Part1.Classes[6] = SUM;
Season17Part1.Classes[7] = RF;
Season17Part1.Classes[8] = GL;
Season17Part1.Classes[9] = RW;
Season17Part1.Classes[10] = SL;
Season17Part1.Classes[11] = GC;
Season17Part1.Classes[12] = WW;
Season17Part1.Classes[13] = LM;

export default Season17Part1;