<template>
    <div class="SubWindowWrapper">
        <div class="PassiveColumnWrapper">
            <div class="Header">
                <div class="Title Gold">Skill Enhancement Passive Option</div>
            </div>
            <div class="SubWindowTop" />
            <div class="SubWindow">
                <template v-for="(skill, index) in skills">
                    <SkillEnhancementPassiveSkill :key="index"
                        v-if="index < 4"
                        :id="skill.id"
                        :passiveSkill="skill.passiveSkill"
                        :passiveSkillName="skill.passiveSkill.name ? skill.passiveSkill.name : $t(`SkillEnhancemnetSkillNames.${skill.passiveSkill.id}`)"
                        :passiveSkillDescription="skill.passiveSkill.description"
                        :tier1PassiveIconId="skill.tier1PassiveIconId"
                        :tier1PassiveRequiredPoints="skill.tier1PassiveRequiredPoints"
                        :tier2PassiveIconId="skill.tier2PassiveIconId"
                        :tier2PassiveRequiredPoints="skill.tier2PassiveRequiredPoints"
                        :tier3PassiveIconId="skill.tier3PassiveIconId"
                        :tier3PassiveRequiredPoints="skill.tier3PassiveRequiredPoints"
                        :isDebuffPassive="false"
                        :currentLevelFromBuild="skill.passiveSkill.currentLevel"
                        :increaseTotalLevel='increaseTotalLevel'
                        :decreaseTotalLevel='decreaseTotalLevel'
                    />
                </template>
            </div>
            <div class="SubWindowBottom" />
        </div>
        <div class="PassiveColumnWrapper">
            <div class="Header">
                <div class="Title Purple">Debuff Enhancement Passive Option</div>
            </div>
            <div class="SubWindowTop" />
            <div class="SubWindow">
                <template v-for="(skill, index) in skills">
                    <SkillEnhancementPassiveSkill :key="index"
                        v-if="index >= 4"
                        :id="skill.id"
                        :passiveSkill="skill.passiveSkill"
                        :passiveSkillName="skill.passiveSkill.name ? skill.passiveSkill.name : $t(`SkillEnhancemnetSkillNames.${skill.passiveSkill.id}`)"
                        :tier1PassiveIconId="skill.tier1PassiveIconId"
                        :tier1PassiveRequiredPoints="skill.tier1PassiveRequiredPoints"
                        :tier2PassiveIconId="skill.tier2PassiveIconId"
                        :tier2PassiveRequiredPoints="skill.tier2PassiveRequiredPoints"
                        :tier3PassiveIconId="skill.tier3PassiveIconId"
                        :tier3PassiveRequiredPoints="skill.tier3PassiveRequiredPoints"
                        :isDebuffPassive="true"
                        :currentLevelFromBuild="skill.passiveSkill.currentLevel"
                        :increaseTotalLevel='increaseTotalLevel'
                        :decreaseTotalLevel='decreaseTotalLevel'
                        :tooltipUp="true"
                    />
                </template>
            </div>
            <div class="SubWindowBottom" />
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import SkillEnhancementPassiveSkill from "./SkillEnhancementPassiveSkill"

export default {
    computed: {
        ...mapState({
            tree: (state) => state.MasterTree.SkillEnhancementTree
        })
    },
    data: () =>{
        return {
            count: 0,
        }
    },
    props: {
        increaseTotalLevel: {
            type: Function
        },
        decreaseTotalLevel: {
            type: Function
        },
        id: {
            type: Number,
            default: 0
        },
        skills: {
            type: Array
        },
    },
    components: {
        SkillEnhancementPassiveSkill,
    },
    methods: {
    }
}
</script>

<style scoped>
    .SubWindowWrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .Header {
        flex: 1 1 14%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .SubWindow {
        background-image: url("../../../assets/ClassStatsBackgroundCenter.png");
        background-size: 100% 100%;
        flex: 1 1 86%;
        display: flex;
        flex-direction: column;
        padding-top: 1%;
    }

    .PassiveColumnWrapper {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
    }

    .SubWindowBottom {
        background-image: url("../../../assets/ClassStatsBackgroundBottom.png");
        background-size: 100% 100%;
        width: 100%;
        flex: 0 1 2.5%;
    }

    .SubWindowTop {
        background-image: url("../../../assets/ClassStatsBackgroundTop.png");
        background-size: 100% 100%;
        width: 100%;
        flex: 0 1 1.5%;
    }

    .passiveSubWindow {
        flex: 0 1 40%;
    }
    
    .SkillLineWrapper {        
        background-image: url("../../../assets/ClassStatsBackgroundCenter.png");
        background-size: 100% 100%;
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        padding: 2% 2% 0% 2%;
    }

    .Title {
        text-align: center;
    }
    
    .Gold {
        color: rgba(277, 165, 0);
    }

    .Purple {
        color: rgba(200, 140, 200);
    }
</style>