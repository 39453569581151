<template>
<div 
    class="MasterTreeColumn"
    v-bind:style="{ backgroundImage: 'url(../../../Tree400/Tree400' + (this.order + 1).toString() + '.png)' }"
>
    <div class="Counter">
        {{this.getColumnName()}}: {{count}}
    </div>
    <div class="MasterTreeColumnSkillsArea">
        <div class="MasterTreeColumnSkillWrapper"  v-if="skills && Object.keys(skills).length > 0">
            <template v-for="(skill, index) in skills">
                <MasterTreeSkill :key="index"
                    :increaseColumnLevel="increaseColumnLevel"
                    :decreaseColumnLevel="decreaseColumnLevel"
                    :id="skill.id"
                    :maxLevel="skill.maxLevel"
                    :hidden="skill.id === 0"
                    :name="!skill.name ? $t(`MasterTreeSkillNames.${skill.id}`) : skill.name"
                    :rank="skill.rank"
                    :requirements="skill.requirements"
                    :columnId="id"
                    :description="skill.description"
                    :values="skill.values"
                    :tooltipUp="skill.rank > 5"
                    :tooltipLeft="order === 2"
                    :currentLevelFromBuild="skill.currentLevel"
                    :iconId="skill.iconId"
                    :descriptionId="skill.descriptionId"
                    :affectedStats="skill.affectedStats"
                />
            </template>
        </div>
    </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import MasterTreeSkill from "./MasterTreeSkill"

export default {
    computed: {
        ...mapState({
        tree: (state) => state.MasterTree.MasterTree
        })
    },
    data: () =>{
        return {
            count: 0,
        }
    },
    props: {
        id: {
            type: Number,
            default: 0
        },
        order: {
            type: Number,
            default: 0
        },
        skills: {
            type: Array
        }
    },
    components: {
        MasterTreeSkill,
    },
    methods: {
        getColumnName()
        {
            if(this.order == 0)
                return "Green";
            else if(this.order == 1)
                return "Blue";
            else if(this.order == 2)
                return "Red";

            return "Points Added"

        },
        increaseColumnLevel(points) {
            this.count += points;
            this.$emit('increaseTotalLevel', points)
        },
        decreaseColumnLevel(points) {
            this.count -= points;
            this.$emit('decreaseTotalLevel', points)
        },
    }
}
</script>

<style scoped>
    .MasterTreeColumn {
        background-size: 100% 100%;
        flex: 1 1 33%;
        display: flex;
        flex-direction: column;
    }
    .Counter {
        padding-top: 3%;
        color:rgba(277, 165, 0);
        text-align: center;
        flex: 1 1 3%;
    }

    .MasterTreeColumnSkillsArea {
        flex: 1 1 97%;
    }
/* 
    @media screen and (max-height: 850px) {
        .MasterTreeColumnSkillWrapper {
            zoom: 80%;
        }
    } */
    .MasterTreeColumnSkillWrapper {
        height: 100%;
        padding: 0% 3.5% 2.5% 3.5%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
</style>