<template>
  <v-app>
    <v-navigation-drawer
      id="drawer"
      v-model="drawer"
      app
      disable-resize-watcher
      floating
      temporary
    >
    
      <ChangelogModal
        :dialog="dialog"
        @closeDialog="closeDialog"
      />
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Vinter Tools
          </v-list-item-title>
          <v-list-item-subtitle>
            Tools for MU Online
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider/>

      <v-list dense nav>
        <v-list-item link v-on:click="push('/')">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-compass</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Explore Builds</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        
        <v-list-item link v-on:click="push('/about')">
          <v-list-item-icon>
            <v-icon>mdi-help-box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list dense nav>
        <v-list-item link v-on:click.stop="{dialog = true}">
            <v-list-item-icon>
              <v-icon>mdi-note</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Changelog</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider/> 
        <div class="subtext">
          WEBZEN IP, game content, and materials are trademarks and copyrights of Webzen Inc., and its Licensors, and is used with permission. <br>© All rights reserved.
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar 
      app
      dense
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class= "pointer" @click="push('/')">Vinter Tools</v-toolbar-title>
      <v-btn text style="color: gray; text-transform: lowercase;" v-on:click.stop="dialog = true">
        v1.2.62
      </v-btn>
      <v-spacer />
      <v-select
          :items="seasons"
          item-text="seasons => seasons.id +' - '+ seasons.code"
          item-value="id"
          label="Select a season"
          dense
          solo
          style="max-width: fit-content;"
          hide-details="true"
          single-line
          color="gray"
          item-color="gray"
          background-color="rgba(0, 0, 0, 0)"
          v-model="selectedSeasonId"
          v-on:change="selectNewSeason"
      >
        <template slot='selection' slot-scope='{ item }'>
          Season {{ item.code }}
        </template>
        <template slot='item' slot-scope='{ item }'>
          Season {{ item.code }}
        </template>
      </v-select>
      <v-btn href="https://discord.gg/3MpEntYZ5s" target="_blank">
        <v-icon style="padding-right: 4px">mdi-discord</v-icon>
        Discord
      </v-btn>
    </v-app-bar>
    <v-main>
      <div v-if="!loading" style="height: 100%;">
        <router-view/>
      </div>
      <div class="loading" v-else>
          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
          <span class="loading-text">{{$t(`Planner.LoadingText.${(Math.floor(Math.random() * 10))}`)}}</span>
      </div>
    </v-main>
    <link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" rel="stylesheet">
    <link href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css" rel="stylesheet">
    <Notifications />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import ChangelogModal from './Components/ChangelogModal.vue';
import Notifications from './Components/Notifications/Notifications.vue';

export default {
  
  computed: {
      ...mapState({
        seasons: (state) => state.Planner.Seasons,
        selectedClassId: (state) => state.Planner.SelectedClassId,
        selectedSeason: (state) => state.Planner.SelectedSeason,
        selectedSeasonId: (state) => state.Planner.SelectedSeason.id 
      })
  },
  data: () => ({ 
    drawer: false,
    loading: true, 
    dialog: false,
  }),
  components: {
    ChangelogModal,
    Notifications
  },
  created() {
    // this.$store.commit('InitializeInventorySlots');
    this.$store.dispatch('Seasons_getAll')
    .then(() => {
      this.$store.dispatch('Classes_getAllClasses', this.selectedSeason)
      .then(() => {
        this.loading = false;
      });
    });
    //Block right click on entire site
    window.addEventListener('contextmenu', function (e) { 
      e.preventDefault(); 
    }, false);
  },
  methods: {
    push(route) {
      this.$router.push(route);
    },
    closeDialog() {
      this.dialog = false
    },
    selectNewSeason(seasonId) {
      this.loading = true;
      this.$store.commit('SetSelectedSeasonById', seasonId)
      this.$store.dispatch('Classes_getAllClasses', this.selectedSeason)
      .then(() => {
          this.loading = false;
          if(this.selectedClassId != 0) {
            this.push("/")
          }
      });
    }
  }
}
</script>

<style>
  .v-select__selections > input {
    display: none;
  }
  .subtext {
    font-size: small;
    color:gray;
    padding: 5%;
  }

  .pointer {
    cursor: pointer;
  }

  .loading-text {
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    position: relative;
  }
  .loading-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }
  .loading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .lds-ring {
  position: relative;
  width: 160px;
  height: 160px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 128px;
    height: 128px;
    margin: 16px;
    border: 16px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  body {
    background-color: black;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 100%;
  }
  html { 
    overflow: hidden !important;
    height: 100%; 
  }
  * {
    font-size: 15px;
  }
</style>
