import Landing from '@/pages/Landing/Landing'
import Planner from '@/pages/Planner/Planner'
import About from '@/pages/About'
const routes = [
  {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/home',
    name: 'landing',
    component: Landing,
  },
  {
    path: '/build/:buildId',
    name: 'Planner',
    component: Planner,
    props: true
  },
  {
    path: '/planner',
    name: 'Planner',
    component: Planner,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '*',
    redirect: "/home"
  },
]


export default routes
