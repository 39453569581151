const StatConsts = {
    Energy: 1,
    Stamina: 2,
    Agility: 3,
    Strength: 4,
    Command: 5,
    AdditionalEnergy: 6,
    AdditionalStamina: 7,
    AdditionalAgility: 8,
    AdditionalStrength: 9,
    AdditionalCommand: 10,
    MinAttackPower: 11,
    MaxAttackPower: 12,
    AtkSuccRate: 13,
    PvPAtkRate: 14,
    Defense: 15,
    AttackSpeed: 16,
    DefenseRate: 17,
    PvPDefRate: 18,
    SkillAtkPwr: 19,
    MinWizardryDmg: 20,
    MaxWizardryDmg: 21,
    AdditionalWizardryDmg: 22,
    MinCurseDmg: 23,
    MaxCurseDmg: 24,
    AdditionalCurseDmg: 25,
    PrxAtkPwr: 26,
    DivAtkPwr: 27,
    AOEAtkPwr: 28,
    RetAtkPwr: 29,
    RageAtkPwr: 30,
}

export default StatConsts;

