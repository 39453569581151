<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="700"
      persistent
    >
      <div class="MainWindow">
        <div class="Gold Title">Variable Stat Addition</div>
        <!-- TODO: spacer -->
        <div class="AdditionalStatButtonsWrapper">
          <!-- Strength -->
          <div class="AdditionalStats">
            <div class="AdditionalStatButton">
              <div 
                  class="SubtractStatButton"
                  v-on:click="decreaseStat($event, 9)"
              />
              <div 
                  class="StatImage Strength"
              />
              <div 
                  class="AddStatButton"
                  v-on:click="increaseStat($event, 9)"
              />
            </div>
            <div class="AdditionalStatPoints">
              <div class="Gold Stat LeftStat">{{currentStrength}}</div>
              <div class="Blue Stat RightStat">{{additionalStrength == 0 ? '-' : `(+${additionalStrength})`}}</div>
            </div>
          </div>
          <!-- Agility -->
          <div class="AdditionalStats">
            <div class="AdditionalStatButton">
              <div 
                  class="SubtractStatButton"
                  v-on:click="decreaseStat($event, 8)"
              />
              <div 
                  class="StatImage Agility"
              />
              <div 
                  class="AddStatButton"
                  v-on:click="increaseStat($event, 8)"
              />
            </div>
            <div class="AdditionalStatPoints">
              <div class="Gold Stat LeftStat">{{currentAgility}}</div>
              <div class="Blue Stat RightStat">{{additionalAgility == 0 ? '-' : `(+${additionalAgility})`}}</div>
            </div>
          </div>
          <!-- Stamina -->
          <div class="AdditionalStats">
            <div class="AdditionalStatButton">
              <div 
                  class="SubtractStatButton"
                  v-on:click="decreaseStat($event, 7)"
              />
              <div 
                  class="StatImage Stamina"
              />
              <div 
                  class="AddStatButton"
                  v-on:click="increaseStat($event, 7)"
              />
            </div>
            <div class="AdditionalStatPoints">
              <div class="Gold Stat LeftStat">{{currentStamina}}</div>
              <div class="Blue Stat RightStat">{{additionalStamina == 0 ? '-' : `(+${additionalStamina})`}}</div>
            </div>
          </div>
          <!-- Energy -->
          <div class="AdditionalStats">
            <div class="AdditionalStatButton">
              <div 
                  class="SubtractStatButton"
                  v-on:click="decreaseStat($event, 6)"
              />
              <div 
                  class="StatImage Energy"
              />
              <div 
                  class="AddStatButton"
                  v-on:click="increaseStat($event, 6)"
              />
            </div>
            <div class="AdditionalStatPoints">
              <div class="Gold Stat LeftStat">{{currentEnergy}}</div>
              <div class="Blue Stat RightStat">{{additionalEnergy == 0 ? '-' : `(+${additionalEnergy})`}}</div>
            </div>
          </div>
        </div>
        <div class="Gold Title">
          Please match your Current Points and Max Points before continuing.<br>
        </div>
        <div
          v-bind:class="[ currentPoints == getMaxPoints() ? 'Green Title' : 'Red Title']"
        >
          Stat Points: {{currentPoints}} / {{getMaxPoints()}}
        </div>
        <div class="ButtonsWrapper">
            <div 
              v-bind:class="[canSave() ? 'TreeLever' : 'TreeLever-Disabled']" 
              v-on:click="closeDialog()"
            >
                <div 
                    v-bind:class="[canSave() ? 'Gold Title Centered' : 'DarkGray Title Centered']" 
                >
                    OK
                </div>
            </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    data () {
      return {
        currentPoints: 0,
      }
    },
    created() {
      this.currentPoints = this.skill.passiveSkill.currentLevel * 8;
    },
    computed: {
        ...mapState({
            currentStrength: (state) => state.Planner.currentStrength,
            currentAgility: (state) => state.Planner.currentAgility,
            currentStamina: (state) => state.Planner.currentStamina,
            currentEnergy: (state) => state.Planner.currentEnergy,
            currentCommand: (state) => state.Planner.currentCommand,
            additionalStrength: (state) => state.Planner.CurrentStats[9],
            additionalAgility: (state) => state.Planner.CurrentStats[8],
            additionalStamina: (state) => state.Planner.CurrentStats[7],
            additionalEnergy: (state) => state.Planner.CurrentStats[6],
            additionalCommand: (state) => state.Planner.CurrentStats[10],
        })
    },
    props: {
        dialog: {
          Type: Boolean,
          Default: false,
        },
        skill: {
          Type: Object,
        }
    },
    methods: {
      getMaxPoints() {
        if (!this.skill || !this.skill.passiveSkill)
          return 0;

        if(!this.skill?.passiveSkill?.values){
            return 0;
        }

        var value = this.skill.passiveSkill.values.find(x => x.level == this.skill.passiveSkill.currentLevel)
        return value?.skillValue || 0;
      },
      canSave() {
        return this.currentPoints == this.getMaxPoints();
      },
      closeDialog() {
        if(this.canSave())
          this.$emit('closeDialog');
      },
      decreaseStat(e, statId) {
        e.preventDefault();
        //Shift key functionality for +10 when points required are 1. (no effect on points required 10)
        var points = 1;
        var additionalStat = this.$store.getters.getStat(statId);

        if(e.shiftKey) {
          points = additionalStat < 80 ? additionalStat : 80;
        }

        if(e.ctrlKey) {
          points = additionalStat < 10 ? additionalStat : 10;
        }

        if (additionalStat - points < 0) {
          return;
        }

        if (this.currentPoints - points < 0) {
          return;
        }

        //Increment the currentLevel
        this.currentPoints -= points;
        this.$store.commit('SetStatValue', { statId: statId, value: -points })
      },
      increaseStat(e, statId) {
        
        e.preventDefault();
        var maxPoints = parseInt(this.getMaxPoints());
        var points = 1;

        if(e.shiftKey) {
          points = maxPoints - this.currentPoints  < 100 ? maxPoints - this.currentPoints : 100;
        }

        if(e.ctrlKey) {
          points = maxPoints - this.currentPoints < 10 ? maxPoints - this.currentPoints : 10;
        }

        if (this.currentPoints + points > maxPoints) {
          return;
        }

        //Increment the currentPoints
        this.currentPoints += points;
        this.$store.commit('SetStatValue', { statId: statId, value: points })
      },
    }
  }
</script>

<style scoped>
    .Title {
      text-align: center;
    }

    .Green {
        color: rgb(106, 226, 160);
    }

    .Red {
        color: rgb(250, 63, 50);
    }

    .Gold {
      color: rgba(277, 165, 0);
    }

    .Purple {
      color: rgba(200, 140, 200);
    }

    .Gray {
      color: rgba(211, 211, 211);
    }

    .DarkGray {
      color: darkgray;
    }

    .Blue {
      color: cornflowerblue;
    }

    .Stat {
      text-align: center;
    }

    .RightStat {
      padding-left: 3%;
    }

    .LeftStat {
      padding-right: 3%;
    }

    .MainWindow {
      width: 100%;
      background: url("../../../assets/background-center.png");
      background-size: 100% 100%;
      padding-top: 2.5%;
      display: flex;
      flex-direction: column;
    }

    .AdditionalStatButtonsWrapper {
      display: flex;
    }

    .AdditionalStats {
      display: flex;
      flex: 1 1 25%;
      flex-direction: column;
    }

    .AdditionalStatPoints {
      display: flex;
      justify-content: center;
    }

    .AdditionalStatButton {
      justify-content: center;
      display: flex;
      width: 100%;
    }

    .AddStatButton {
      background-image: url("../../../assets/AddStatButtonIdle.png");
      background-size: 100% 100%;
      height: 19px;
      width: 19px;
      align-self: center;
    }

    .AddStatButton:hover {
      background-image: url("../../../assets/AddStatButtonHover.png");
    }

    .AddStatButton:active {
      background-image: url("../../../assets/AddStatButtonPressed.png");
    }

    .SubtractStatButton {
      background-image: url("../../../assets/SubtractStatButtonIdle.png");
      background-size: 100% 100%;
      height: 19px;
      width: 19px;
      align-self: center;
    }

    .SubtractStatButton:hover {
      background-image: url("../../../assets/SubtractStatButtonHover.png");
    }

    .SubtractStatButton:active {
      background-image: url("../../../assets/SubtractStatButtonPressed.png");
    }

    .StatImage {
      background-size: 100% 100%;
      height: 64px;
      width: 64px;
      margin: 10% 10% 3% 10%;
    }

    .Strength {
      background-image: url("../../../assets/additionalstat-strength.png");
    }

    .Agility {
      background-image: url("../../../assets/additionalstat-agility.png");
    }

    .Stamina {
      background-image: url("../../../assets/additionalstat-stamina.png");
    }

    .Energy {
      background-image: url("../../../assets/additionalstat-energy.png");
    }

    .ButtonsWrapper {
      height: 4.5vh;
      display: flex;
      justify-content: center;
      margin-top: 2%;
      margin-bottom: 2.5%;
    }

    .TreeLever {
      background-image: url("../../../assets/TreeLeverNormal.png");
      background-size: 100% 100%;
      width: 10%;
      align-items: center;
      justify-content: center;
      height: 100%;
      display: flex;
    }
    
    .TreeLever-Disabled {
      background-image: url("../../../assets/TreeLeverDisabled.png");
      background-size: 100% 100%;
      width: 10%;
      align-items: center;
      justify-content: center;
      height: 100%;
      display: flex;
    }

    .TreeLever:hover {
        background-image: url("../../../assets/TreeLeverHover.png");
    }

    .TreeLever:active {
        background-image: url("../../../assets/TreeLeverClicked.png");
    }
</style>