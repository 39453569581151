<template>
    <div class="SubWindowWrapper">
        <div class="SubWindowTop"/>
        <div class="SubWindow">
            <div class="SkillsWrapper">
                <div class="SkillLineWrapper">
                    <template v-for="(skill, index) in skillLineSkills">
                        <SkillEnhancementSkill 
                            :key="index"
                            :id="skill.id"
                            :maxLevel="skill.maxLevel"
                            :hidden="skill.id === 0"
                            :name="skill.name ? skill.name : $t(`SkillEnhancemnetSkillNames.${skill.id}`)"
                            :description="skill.description"
                            :requirements="skill.requirements"
                            :values="skill.values"
                            :currentLevelFromBuild="skill.currentLevel"
                            :iconId="skill.iconId"
                            :affectedStats="skill.affectedStats"
                            :border="'mt800skillbg_yellow.png'"
                            :pageId="id"
                            :type="'skill'"
                            :increaseTotalLevel='increaseTotalLevel'
                            :decreaseTotalLevel='decreaseTotalLevel'
                            :increaseTypePoints='increaseSkillLinePoints'
                            :decreaseTypePoints='decreaseSkillLinePoints'
                            :isSkillRequiredFromMasterTree="skill.requirements.isSkillRequiredFromMasterTree ? skill.requirements.isSkillRequiredFromMasterTree : index == 0"
                        />
                    </template>
                </div>
                <div class="DebuffLineWrapper">
                    <template v-for="(skill, index) in debuffLine1Skills">
                        <SkillEnhancementSkill :key="index"
                            :id="skill.id"
                            :maxLevel="skill.maxLevel"
                            :hidden="skill.id === 0"
                            :name="skill.name ? skill.name : $t(`SkillEnhancemnetSkillNames.${skill.id}`)"
                            :description="skill.description"
                            :requirements="skill.requirements"
                            :values="skill.values"
                            :currentLevelFromBuild="skill.currentLevel"
                            :iconId="skill.iconId"
                            :affectedStats="skill.affectedStats"
                            :border="'mt800skillbg_purple.png'"
                            :mainDebuff2Id="mainDebuff2Id"
                            :pageId="id"
                            :type="'debuff'"
                            :increaseTotalLevel='increaseTotalLevel'
                            :decreaseTotalLevel='decreaseTotalLevel'
                            :increaseTypePoints='increaseDebuffPoints'
                            :decreaseTypePoints='decreaseDebuffPoints'
                        />
                    </template>
                </div>
                <div class="DebuffLineWrapper">
                    <template v-for="(skill, index) in debuffLine2Skills">
                        <SkillEnhancementSkill :key="index"
                            :id="skill.id"
                            :maxLevel="skill.maxLevel"
                            :hidden="skill.id === 0"
                            :name="skill.name ? skill.name : $t(`SkillEnhancemnetSkillNames.${skill.id}`)"
                            :description="skill.description"
                            :requirements="skill.requirements"
                            :values="skill.values"
                            :currentLevelFromBuild="skill.currentLevel"
                            :iconId="skill.iconId"
                            :affectedStats="skill.affectedStats"
                            :border="'mt800skillbg_purple.png'"
                            :mainDebuff1Id="mainDebuff1Id"
                            :pageId="id"
                            :type="'debuff'"
                            :increaseTotalLevel='increaseTotalLevel'
                            :decreaseTotalLevel='decreaseTotalLevel'
                            :increaseTypePoints='increaseDebuffPoints'
                            :decreaseTypePoints='decreaseDebuffPoints'
                            :tooltipUp="true"
                        />
                    </template>
                </div>
                <div class="LineSpacer"/>
                <div class="DebuffLineWrapper">
                    <template v-for="(skill, index) in additionalDebuffSkills">
                        <SkillEnhancementSkill :key="index"
                            :id="skill.id"
                            :maxLevel="skill.maxLevel"
                            :hidden="skill.id === 0"
                            :name="skill.name ? skill.name : $t(`SkillEnhancemnetSkillNames.${skill.id}`)"
                            :description="skill.description"
                            :requirements="skill.requirements"
                            :values="skill.values"
                            :currentLevelFromBuild="skill.currentLevel"
                            :iconId="skill.iconId"
                            :affectedStats="skill.affectedStats"
                            :border="'mt800skillbg_green.png'"
                            :pageId="id"
                            :increaseTotalLevel='increaseTotalLevel'
                            :decreaseTotalLevel='decreaseTotalLevel'
                            :tooltipUp="true"
                        />
                    </template>
                </div>
            </div>
        </div>
        <div class="SubWindowBottom"/>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import SkillEnhancementSkill from "./SkillEnhancementSkill"

export default {
    computed: {
        ...mapState({
            tree: (state) => state.MasterTree.SkillEnhancementTree
        })
    },
    data: () =>{
        return {
            count: 0,
        }
    },
    props: {
        increaseTotalLevel: {
            type: Function
        },
        decreaseTotalLevel: {
            type: Function
        },
        increaseSkillLinePoints: {
            type: Function
        },
        decreaseSkillLinePoints: {
            type: Function
        },
        increaseDebuffPoints: {
            type: Function
        },
        decreaseDebuffPoints: {
            type: Function
        },
        id: {
            type: Number,
            default: 0
        },
        skillLineSkills: {
            type: Array
        },
        debuffLine1Skills: {
            type: Array
        },
        debuffLine2Skills: {
            type: Array
        },
        additionalDebuffSkills: {
            type: Array
        },
        mainDebuff1Id: {
            type: Number,
            default: 0
        },
        mainDebuff2Id: {
            type: Number,
            default: 0
        },
    },
    components: {
        SkillEnhancementSkill,
    },
    methods: {
    }
}
</script>

<style scoped>
    .SubWindowWrapper {
        padding: 0vh 1vh 1vh 1vh;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .SubWindowBottom {
        background-image: url("../../../assets/ClassStatsBackgroundBottom.png");
        background-size: 100% 100%;
        height: 1vh;
        width: 100%;
    }

    .SubWindowTop {
        background-image: url("../../../assets/ClassStatsBackgroundTop.png");
        background-size: 100% 100%;
        height: 1vh;
        width: 100%;
    }

    .SubWindow {
        background-image: url("../../../assets/ClassStatsBackgroundCenter.png");
        background-size: 100% 100%;
        padding: 1% 3.5% 0% 3.5%;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .SkillsWrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }
    
    .SkillLineWrapper {
        display: flex;
        height: 100%;
        width: 100%;
        flex: 1 1 0%;
    }

    .DebuffLineWrapper {
        display: flex;
        height: 100%;
        width: 100%;
        flex-wrap: wrap;
        flex: 1 1 14.28%;
    }

    .LineSpacer {
        width: 80%;
        background-image: url("../../../assets/LineSpacer.png");
        background-size: 100% 100%;
        flex: 0 0 1.5%;
        align-self: center;
    }
</style>