<template>
    <div class="PassiveWrapper">
        <FourthStatModal
            :dialog="dialog"
            @closeDialog="closeDialog"
            :skill="this"
            v-if="this.passiveSkill.isFourthStat == true || (this.passiveSkill.isFourthStat == undefined && (this.passiveSkill.id == 39 || this.passiveSkill.id == 64))"
        />
        <div class="MasterTreeSkillWrapper">
            <div class="MasterTreeSkillBackground"
                v-bind:style="[{backgroundImage: this.isDebuffPassive ? 'url(../../../mt800skillbg_purple.png)' : 'url(../../../mt800skillbg_yellow.png)' }]"
            >
                <div 
                    class="MasterTreeSkillIcon skill-tooltip" 
                    v-bind:class="[!this.isTier1Unlocked() ? disabled : '' ]"
                    @contextmenu="decreaseCurrentLevel($event)" 
                    v-on:click="increaseCurrentLevel($event)"
                    v-bind:style="{ backgroundImage: 'url(../../../imgs/4th_skill_icon_' + this.getPassiveIconId().toString().padStart(2, '0') + '.png)' }"
                    @mouseenter="focus = true"
                    @mouseleave="focus = false"
                />
                    <span class="skill-tooltip-text"
                        v-bind:class="[ this.tooltipUp ? 'tooltipUp' : '', this.tooltipLeft ? 'tooltipLeft' : '' ]"
                    >
                        <span class="MasterTreeSkillTitle">{{this.passiveSkillName}}</span><br>
                        {{$t("SkillEnhancementTree.SkillGrade", { currentLevel: this.passiveSkill.currentLevel, maxLevel: getMaxLevel() })}}<br>
                        {{GetDescription(this.getCurrentValue())}} 

                        <div v-if="focus && this.$store.getters.getCtrlPressed" style="display: flex;">
                            <div class="skillValueTable">
                                <div v-for="(level, index) in passiveSkill.values" :key="index">
                                    <div v-if="index < passiveSkill.maxLevel / 2">
                                        <span v-bind:class="[level.level === passiveSkill.currentLevel ? 'Gold' : '']">Level {{level.level}}: {{level.skillValue}}<br></span>
                                    </div>
                                </div> 
                            </div>
                            <div class="skillValueTable">
                                <div v-for="(level, index) in passiveSkill.values" :key="index">
                                    <div v-if="index >= passiveSkill.maxLevel / 2">
                                        <span v-bind:class="[level.level === passiveSkill.currentLevel ? 'Gold' : '']">Level {{level.level}}: {{level.skillValue}}<br></span>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div v-else>
                            <span v-if="ShouldShowNextDescription()">
                                <br>
                                <span class="NextLevelTitle">{{$t(`MasterTree.MasterTreeSkillNextLevel`)}}</span><br>
                                <span>{{GetDescription(this.getNextValue())}} </span>
                            </span>              
                            <div> 
                                <br>
                                <span class="RequirementsTitle">{{$t("SkillEnhancementTree.NextActivationLevel")}}</span><br>
                                <span class="RequirementNotMet" v-if="ShouldShowRequirements()">
                                    {{$t(`SkillEnhancementTree.${isDebuffPassive ? 'DebuffPassiveSlotPoints' : 'SkillLinePassiveSlotPoints'}`, { value: getNextTierPoints() - (isDebuffPassive ? skillEnhancementTreeDebuffPoints : skillEnhancementTreeSkillLinePoints) })}}
                                    <br>
                                </span>
                                <span v-if="isTier3Unlocked()">
                                    {{$t('SkillEnhancementTree.MaxLevelAvailableUnlocked')}}
                                </span>
                                <span v-else>
                                    {{$t('SkillEnhancementTree.MaxLevelAvailable', { tier: getNextTierNumber(), maxLevel: getNextTierMaxLevel() })}}
                                </span>
                            </div>
                        </div>                
                    </span>
                <div class="MasterTreeSkillCounterWrapper">
                    <div 
                        class="PassiveLock"
                        v-bind:style="[{ visibility: shouldShowLock() ? 'visible' : 'hidden' }, {backgroundImage: this.isDebuffPassive ? 'url(../../../PassiveLockDebuff.png)' : 'url(../../../PassiveLockSkill.png)' }]"
                    />
                    <div class="MasterTreeSkillCounter">{{this.passiveSkill.currentLevel}}</div>
                </div>
            </div>
        </div>
        <div class="PassiveText">
            <span v-bind:class="this.isDebuffPassive ? 'Purple' : 'Gold'">{{this.passiveSkillName}}</span> <br/>
            
            <span class="Gray Small-Text" v-if="isTier3Unlocked()">
                Maximum Activation Level
            </span>
            <span class="Gray Small-Text" v-else>
                Enhancement Slot Add-Up
            </span>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import FourthStatModal from "./FourthStatModal"

export default {
    computed: {
        ...mapState({
            selectedSeason: (state) => state.Planner.SelectedSeason,
            skillEnhancementTreeSkillLinePoints: (state) => state.MasterTree.SkillEnhancementTreeSkillLinePoints,
            skillEnhancementTreeDebuffPoints: (state) => state.MasterTree.SkillEnhancementTreeDebuffPoints,
            treePoints: (state) => state.MasterTree.SkillEnhancementTreePoints,
        })
    },
    created() {
        if(this.id != 0) {
            if(this.currentLevelFromBuild != 0) {
                this.passiveSkill.currentLevel = this.currentLevelFromBuild;
                this.increaseTotalLevel(this.currentLevelFromBuild)
            }
            this.$store.commit('AddSkillEnhancementPassive', { id: this.passiveSkill.id, currentLevel: this.currentLevelFromBuild, isDebuffPassive: this.isDebuffPassive, tier1PassiveRequiredPoints: this.tier1PassiveRequiredPoints, tier2PassiveRequiredPoints: this.tier2PassiveRequiredPoints, tier3PassiveRequiredPoints: this.tier3PassiveRequiredPoints, name: this.passiveSkillName  });
        }
    },
    data: function() {
        return {
            disabled: 'MasterTreeSkillIconDisabled',
            focus: false,
            dialog: false,
        }
    },
    components: {
        FourthStatModal,
    },
    props: {
        increaseTotalLevel: {
            type: Function
        },
        decreaseTotalLevel: {
            type: Function
        },
        id: {
            type: Number,
            Default: 0
        },
        passiveSkill: {
            type: Object,
        },
        currentLevelFromBuild: {
            type: Number,
            Default: 0
        },
        tier1PassiveIconId: {
            type: Number,
        },
        tier1PassiveRequiredPoints: {
            type: Number,
        },
        tier2PassiveIconId: {
            type: Number,
        },
        tier2PassiveRequiredPoints: {
            type: Number,
        },
        tier3PassiveIconId: {
            type: Number,
        },
        tier3PassiveRequiredPoints: {
            type: Number,
        },
        isDebuffPassive: {
            type: Boolean,
        },
        tooltipUp: {
            type: Boolean,
            default: false
        },
        tooltipLeft: {
            type: Boolean,
            default: true
        },
        passiveSkillName: {
            type: String,
            default: "Name Error"
        },
        passiveSkillDescription: {
            type: String,
            default: ""
        }
    },
    methods: {
        closeDialog() {
        this.dialog = false
        },
        isTier1Unlocked() {
            if(this.isDebuffPassive) {
                return this.skillEnhancementTreeDebuffPoints >= this.tier1PassiveRequiredPoints;
            }
            return this.skillEnhancementTreeSkillLinePoints >= this.tier1PassiveRequiredPoints;
        },
        isTier2Unlocked() {
            if(this.isDebuffPassive) {
                return this.skillEnhancementTreeDebuffPoints >= this.tier2PassiveRequiredPoints;
            }
            return this.skillEnhancementTreeSkillLinePoints >= this.tier2PassiveRequiredPoints;
        },
        isTier3Unlocked() {
            if(this.isDebuffPassive) {
                return this.skillEnhancementTreeDebuffPoints >= this.tier3PassiveRequiredPoints;
            }
            return this.skillEnhancementTreeSkillLinePoints >= this.tier3PassiveRequiredPoints;
        },
        getNextTierNumber() {
            if(this.isTier2Unlocked())
                return 3;
            if(this.isTier1Unlocked())
                return 2;
            return 1;
        },
        getMaxLevel() {
            if(this.isTier3Unlocked())
                return 40;
            if(this.isTier2Unlocked())
                return 20;
            return 10;
        },
        getNextTierMaxLevel() {
            if(this.isTier2Unlocked())
                return 40;
            if(this.isTier1Unlocked())
                return 20;
            return 10;
        },
        isTier1 () {
            return this.passiveSkill.currentLevel >= 0 && this.passiveSkill.currentLevel < 10;
        }, 
        isTier2() {
            return this.passiveSkill.currentLevel > 10 && this.passiveSkill.currentLevel <= 20;
        }, 
        isTier3() {
            return this.passiveSkill.currentLevel > 20 && this.passiveSkill.currentLevel <= 40;
        },
        getPassiveIconId() {
            if(this.isTier1())
                return this.tier1PassiveIconId;
            if(this.isTier2())
                return this.tier2PassiveIconId;
            if(this.isTier3())
                return this.tier3PassiveIconId;
            return this.tier1PassiveIconId;
        },
        ShouldShowNextDescription() {
            return this.id != 0 && this.passiveSkill.currentLevel > 0 && this.passiveSkill.currentLevel < this.passiveSkill.maxLevel;
        },
        ShouldShowRequirements() {
            return !this.isTier3Unlocked()
        },
        getCurrentValue() {
            if(this.passiveSkill.currentLevel === 0){
                return this.getNextValue()
            }

            if(!this.passiveSkill.values){
                return 0;
            }

            var value = this.passiveSkill.values.find(x => x.level == this.passiveSkill.currentLevel)

            return value?.skillValue || 0;
        },
        getNextValue() {
            if(!this.passiveSkill.values){
                return 0;
            }
            let value = this.passiveSkill.values.find(x => x.level == this.passiveSkill.currentLevel + 1)
            return value?.skillValue || 0;
        },
        IsPointRequirementMet() {
          return 1 + this.treePoints <= this.selectedSeason.maxLevel - 800;  //hardcoded 1 req for passives
        },
        getNextTierPoints() {
            if(this.isTier2Unlocked())
                return this.tier3PassiveRequiredPoints;
            if(this.isTier1Unlocked())
                return this.tier2PassiveRequiredPoints;
            return this.tier1PassiveRequiredPoints;
        },
        IsSkillTypePointsRequirementMet() {
            if(this.isDebuffPassive) {
                if(this.isTier1())
                    return this.skillEnhancementTreeDebuffPoints >= this.tier2PassiveRequiredPoints;
                if(this.isTier2())
                    return this.skillEnhancementTreeDebuffPoints >= this.tier3PassiveRequiredPoints;
                return this.skillEnhancementTreeDebuffPoints >= this.tier1PassiveRequiredPoints;
            }
        
            if(this.isTier1())
                return this.skillEnhancementTreeSkillLinePoints >= this.tier2PassiveRequiredPoints;
            if(this.isTier2())
                return this.skillEnhancementTreeSkillLinePoints >= this.tier3PassiveRequiredPoints;
            return this.skillEnhancementTreeSkillLinePoints >= this.tier1PassiveRequiredPoints;
        },
        CanAddPoints() {
            return this.IsPointRequirementMet() && this.isTier1Unlocked()
        },
        getValueAt(level) {
            if(level === 0){
                return 0;
            }

            if(!this.passiveSkill.values){
                return 0;
            }

            var value = this.passiveSkill.values.find(x => x.level == level)
            return value?.skillValue || 0;
        },
        increaseCurrentLevel(e) {
            e.preventDefault();
            
            //4th Stat Modal Hardcoded because lazy
            if(this.passiveSkill.isFourthStat == true || (this.passiveSkill.isFourthStat == undefined && (this.passiveSkill.id == 39 || this.passiveSkill.id == 64)))
            {
                this.dialog = true;
            }

            if(!this.CanAddPoints()) {
                this.$store.dispatch('addNotification', {
                    text: 'Requirements not met.',
                    withAction: false
                });
                return;
            }
            
            //Shift key functionality for +10 when points required are 1. (no effect on points required 10)
            var points = 1
            if(points === 1 && e.shiftKey)
            {
                points = 10;
            }
            
            //If I don't have enough free points, reduce it to the amount of points that I have left.
            if(this.treePoints + points > (this.selectedSeason.maxLevel - 800)) {
                points = (this.selectedSeason.maxLevel - 800) - this.treePoints;
            }
            
            //If I would surpass the max level by adding the amount of points the user wants to add change points.
            if(this.passiveSkill.currentLevel + points > this.getMaxLevel())
            {
                points = this.getMaxLevel() - this.passiveSkill.currentLevel;
            }

            
            //Increment the currentLevel
            let valueBefore = this.getValueAt(this.passiveSkill.currentLevel);
            this.passiveSkill.currentLevel += points;     
            let currentValue = this.getValueAt(this.passiveSkill.currentLevel) - valueBefore;

            this.increaseTotalLevel(points);       
            this.$store.commit('SetkillEnhancementPassiveLevel', { id: this.passiveSkill.id, currentLevel: this.passiveSkill.currentLevel });
            for (let i = 0; i < this.passiveSkill.affectedStats.length; i++) {
                const statId = this.passiveSkill.affectedStats[i];
                this.$store.commit('SetStatValue', { statId: statId, value: currentValue })
            }            
        },
        decreaseCurrentLevel(e) {
            e.preventDefault();

            //Shift key functionality for +10 when points required are 1. (no effect on points required 10)
            var points = 1
            if(points === 1 && e.shiftKey)
            {
                points = 10;
            }

            //4th Stat Modal Hardcoded because lazy
            if(this.id == 2 || this.id == 6)
            {
                this.dialog = true;
            }

            //Prevent going below 0 currentLevel.
            if(this.passiveSkill.currentLevel - points < 0)
            {
                points = this.passiveSkill.currentLevel;
                if(points <= 0)
                    return;
            }

            //Decrement the currentLevel
            this.decreaseTotalLevel(points);
            let valueBefore = this.getValueAt(this.passiveSkill.currentLevel);
            this.passiveSkill.currentLevel -= points;
            let currentValue = this.getValueAt(this.passiveSkill.currentLevel) - valueBefore;
            this.$store.commit('SetkillEnhancementPassiveLevel', { id: this.passiveSkill.id, currentLevel: this.passiveSkill.currentLevel });
            for (let i = 0; i < this.passiveSkill.affectedStats.length; i++) {
                const statId = this.passiveSkill.affectedStats[i];
                this.$store.commit('SetStatValue', { statId: statId, value: currentValue })
            }   
        },
        shouldShowLock() {
            return !this.isTier1Unlocked() || (this.getMaxLevel() === this.passiveSkill.currentLevel)
        },
        GetDescription(value)
        {
            if(this.passiveSkill.description && this.passiveSkill.description != "")
                return this.passiveSkill.description.replace("#value#", value)
            return this.$t(`SkillEnhancementSkills.${this.passiveSkill.id}`, { value: value })
        }
    }
}
</script>

<style scoped>
    .MasterTreeSkillWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 22%;
        padding-bottom: 8%;
    }

    .MasterTreeSkillBackground {
        display: flex;
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
    }

    .MasterTreeSkillIcon {
        background-size: 100% 100%;
        height: 80%;
        margin-left: 7%;
        flex: 0 1 58%;
        align-self: center;
    }

    .RequirementsTitle {
        color:rgb(147, 201, 255);
    }

    .NextLevelTitle {
        color: rgb(106, 226, 160);
    }

    .RequirementNotMet {
        color: rgb(250, 63, 50);
    }

    .MasterTreeSkillTitle {
        color: rgba(277, 165, 0);
        overflow: hidden;
        white-space: nowrap;
    }

    .MasterTreeSkillIconDisabled {
        opacity: 0.25;
    }

    
    .PassiveLock {
        width: 19px;
        height: 22px;
        background-size: 100% 100%;
    }

    .MasterTreeSkillCounterWrapper {
        color: rgba(211, 211, 211);
        display: flex;
        flex-direction: column;
        flex: 0 1 50%;
        align-self: center;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    /* .MasterTreeSkillCounter {
        
    } */


    .skill-tooltip-text {
        visibility: hidden;
        background-color: black;
        opacity: 0.90;
        color: #fff;
        text-align: center;
        padding: 5px 5px 5px 5px;
        margin-left: 60px;
        /* min-width: 280px;
        max-width: 330px; */
        width: max-content;  
        border: 1px solid gray;;
        position: absolute;
        z-index: 1;        
    }

    .skill-tooltip:hover + .skill-tooltip-text {
        visibility: visible;
    }

    .tooltipLeft {
        transform: translateX(calc(-100% - 75px));
    }

    .tooltipUp {
        transform: translateY(calc(-100% + 50px));
    }

    .tooltipUp.tooltipLeft {
        transform: translateY(calc(-100% + 50px)) translateX(calc(-100% - 75px));
    }

    .skillValueTable {
        flex: 1 1 50%;
        color: #fff;
    }

    .PassiveWrapper {
        display: flex;
        height: 100%;
        padding-left: 4%;
    }
    
    .Gold {
        color: rgba(277, 165, 0);
    }

    .Purple {
        color: rgba(200, 140, 200);
    }

    .Gray {
        color: rgba(211, 211, 211)
    }

    .PassiveText {
        padding-left: 5%;
        flex: 1 1 77%;
    }

    .Small-Text {
        font-size: 12px;
    }
</style>