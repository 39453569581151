import StatConsts from '@/Formulas/StatConsts.js'

const level = 228;

function getMultiplier(stat) {
    const section1 = 1500;
    const section1Percentage = 10;
    const section2 = 2000;
    const section2Percentage = 30;
    const section3 = 3000;
    const section3Percentage = 45;

    var percentage = 1;
    if (stat <= section1) {
        percentage += (section1Percentage / section1) * stat / 100;
    }
    if (stat > section1 && stat <= section2) {
        percentage += section1Percentage / 100 + ((section2Percentage - section1Percentage) / (section2 - section1)) * (stat - section1) / 100;
    }
    if (stat > section2 && stat <= section3) {
        percentage += section2Percentage / 100 + ((section3Percentage - section2Percentage) / (section3 - section2)) * (stat - section2) / 100;
    }
    if (stat > section3) {
        percentage += section3Percentage / 100;
    }
    return percentage;
}

function calcMinAttackPower() {
    return parseInt((this.currentStamina + this.CurrentStats[StatConsts.Stamina] + this.CurrentStats[StatConsts.AdditionalStamina]) / 15 + (this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) / 7 * getMultiplier(this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) + this.CurrentStats[StatConsts.MinAttackPower]);
}

function calcMaxAttackPower() {
    return parseInt((this.currentStamina + this.CurrentStats[StatConsts.Stamina] + this.CurrentStats[StatConsts.AdditionalStamina]) / 12 + (this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) / 5 * getMultiplier(this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) + this.CurrentStats[StatConsts.MaxAttackPower]);
}

function calcAttackSuccessRate() {
    return parseInt(level * 3 + (this.currentStrength + this.CurrentStats[StatConsts.Strength] + this.CurrentStats[StatConsts.AdditionalStrength]) / 6 + (this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) * 1.25 + this.CurrentStats[StatConsts.AtkSuccRate])
}

function calcPvPAttackSuccessRate() {
    return parseInt(level * 2.6 + (this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) * 3.6 + this.CurrentStats[StatConsts.PvPAtkRate])
}

function calcDefense() {
    return parseInt((this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 8 + this.CurrentStats[StatConsts.Defense])
}

function calcAttackSpeed() {
    return parseInt((this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 9 + this.CurrentStats[StatConsts.AttackSpeed]) + "/ 441"
}

function calcDefenseRate() {
    return parseInt((this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 10 * (this.CurrentStats[StatConsts.DefenseRate] / 100 + 1))
}

function calcPvPDefenseRate() {
    return parseInt((level * 1.5) + (this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 5 + this.CurrentStats[StatConsts.PvPDefRate])
}

function calcPrxAtkPwr() {
    return parseInt(50 + (this.currentStamina + this.CurrentStats[StatConsts.Stamina] + this.CurrentStats[StatConsts.AdditionalStamina]) / 10)
}

function calcDivAtkPwr() {
    return parseInt(50 + (this.currentEnergy + this.CurrentStats[StatConsts.Energy] + this.CurrentStats[StatConsts.AdditionalEnergy]) / 10)
}

function calcAOEAtkPwr() {
    return parseInt(100 + (this.currentAgility + this.CurrentStats[StatConsts.Agility] + this.CurrentStats[StatConsts.AdditionalAgility]) / 8 + (this.currentEnergy + this.CurrentStats[StatConsts.Energy] + this.CurrentStats[StatConsts.AdditionalEnergy]) / 10)
}

var RF = {
    Stats: [],
    calculate: function(statId) {
        if(!this.Stats[statId]) {
            return "?";
        }
        return this.Stats[statId].call(window.store.state.Planner);
    }
}

RF.Stats[StatConsts.MinAttackPower] = calcMinAttackPower;
RF.Stats[StatConsts.MaxAttackPower] = calcMaxAttackPower;
RF.Stats[StatConsts.AtkSuccRate] = calcAttackSuccessRate;
RF.Stats[StatConsts.PvPAtkRate] = calcPvPAttackSuccessRate;
RF.Stats[StatConsts.Defense] = calcDefense;
RF.Stats[StatConsts.AttackSpeed] = calcAttackSpeed;
RF.Stats[StatConsts.DefenseRate] = calcDefenseRate;
RF.Stats[StatConsts.PvPDefRate] = calcPvPDefenseRate;
RF.Stats[StatConsts.PrxAtkPwr] = calcPrxAtkPwr;
RF.Stats[StatConsts.DivAtkPwr] = calcDivAtkPwr;
RF.Stats[StatConsts.AOEAtkPwr] = calcAOEAtkPwr;

export default RF;